import React from "react";
import { useTranslation } from "react-i18next";

function TopCard() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const userName = localStorage.getItem("USER_NAME");
  const userColor = localStorage.getItem("USER_COLOR");
  const userNativeType = localStorage.getItem("USER_NATIVE_TYPE");
  const userCharaTagline = localStorage.getItem("USER_CHARA_TAGLINE");
  const userQRColor = localStorage.getItem("USER_QR_COLOR");

  return (
    <div className="relative overflow-hidden shadow-lg rounded-xl">
      <div
        className=" flex items-center space-x-2 px-2 py-4 border-t-8"
        style={{ borderColor: userColor }}
      >
        <div className="absolute right-8 top-5 grid grid-cols-4 gap-1">
          {Array(20)
            .fill("")
            .map(() => {
              return (
                <div
                  className="z-0 h-[4px] w-[4px] rounded-full opacity-70"
                  style={{ background: userColor }}
                />
              );
            })}
        </div>

        {userNativeType && (
          <img
            alt=""
            src={`https://api.be-native.life/api/friends/charimg/${userNativeType}`}
            style={{
              zIndex: 1,
              width: "100px",
              height: "100px",
              marginTop: "-24px",
            }}
          />
        )}
        <div>
          <h1
            className="text-[12px] text-black font-bold mb-4 w-[70%] "
            dangerouslySetInnerHTML={{
              __html: t("native_card_title", { userName }),
            }}
          />
          <p style={{ color: userColor }} className="text-[12px] font-bold">
            "{userCharaTagline}"
          </p>
          <p style={{ color: userColor }} className="text-[28px] font-bold">
            {userNativeType}
          </p>
        </div>
      </div>
      <div
        className="w-[250px] flex items-center justify-between absolute top-[120px] right-[-10%]"
        style={{ transform: "translate(-50%)" }}
      >
        <div
          className="w-6 h-6 rounded-full opacity-50"
          style={{ background: userColor }}
        />
        <div
          className="w-3 h-3 rounded-full opacity-80"
          style={{ background: userColor }}
        />
      </div>
      <div
        style={{ background: userColor, opacity: 0.3 }}
        className="w-[950px] h-[950px] rounded-full absolute left-[-280px] bottom-[-920px] z-0"
      />
    </div>
  );
}

export default TopCard;
