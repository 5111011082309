import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backButton } from "../../assets/icons";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { getFileUrl } from "../../components/ImageUpload/constants";
import useResetScroll from "../../utils/useResetScroll";

export default function CommunitiesSeeAll() {
  useResetScroll();
  const navigate = useNavigate();
  const { categoryKey, categoryTitle, categorySelected } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const userId = localStorage.getItem("USER_ID");

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/travel/favcards?user_id=${userId}&category=${encodeURIComponent(
        categorySelected
      )}`
    );
    const apiData = await apiResponse.json();
    setData(apiData?.[categoryKey]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [categorySelected]);

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="otaPageBg w-full min-h-[100svh] relative">
      <div className="max-w-[450px] mx-auto relative">
        <div className="w-full min-h-[100svh]">
          <div className="p-5 w-full h-[60px] bg-white flex items-center">
            <div className="max-w-[450px] mx-auto w-full">
              <div className="flex items-center gap-2">
                <div onClick={() => navigate(-1)}>
                  <img className="w-[24px]" src={backButton} />
                </div>
                <p className="font-semibold text-[17px] text-[#414853]">
                  {categoryTitle}
                </p>
              </div>
            </div>
          </div>
          <div className="p-5 max-w-[450px] mx-auto">
            <div className="grid grid-cols-3 gap-3 w-full">
              {data?.map(({ favcard_id, imageId, title }) => {
                return (
                  <Link to={`/community/${favcard_id}`} key={favcard_id}>
                    <div className="min-w-[90px] flex flex-col gap-3 items-center">
                      <img
                        src={getFileUrl(imageId)}
                        className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
                        style={{ border: "1px solid #D0D5DD" }}
                      />
                      <p className="otaLight text-[12px]">{title}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
