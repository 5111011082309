import React, { useEffect, useState } from "react";
import useResetScroll from "../../utils/useResetScroll";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import OtaGradientButton from "../../components/OtaGradientButton";
import {
  action,
  calender,
  career,
  health,
  iconicQuotes,
  love,
  mainIcon,
  money,
  niceDay,
  todayDish,
  adviceIcon,
} from "../../assets/astrologyicons";
import AstroPredictionCard from "./components/AstroPredictionCard";
import { useTranslation } from "react-i18next";

export default function Astrology() {
  useResetScroll();

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [isTransparent, setIsTransparent] = useState(true);
  const [data, setData] = useState([]);
  const [mappedAstrologyConfig, setMappedAstrologyConfig] = useState([]);
  const [characters, setCharacters] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const userId = localStorage.getItem("USER_ID");

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const userInfoAPIRes = await fetch(
          ` https://api.be-native.life/api/users/${userId}`
        );
        const userInfo = await userInfoAPIRes.json();

        const date = userInfo?.dob?.split("-")?.[2];
        const month = userInfo?.dob?.split("-")?.[1];
        const year = userInfo?.dob?.split("-")?.[0];
        console.log("date,month,year", date, month, year);

        const apires = await fetch(
          `https://api.be-native.life/api/daily/astro/${year}/${month}/${date}`
        );
        const data = await apires.json();
        setData(data);

        const charApiRes = await fetch(
          `https://api.be-native.life/api/friends/charimg_urls_by_user_id/${userId}`
        );
        const charList = await charApiRes.json();
        setCharacters(charList);
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId]);

  useEffect(() => {
    const { advice, astro } = data;
    if (!astro || !advice) {
      return;
    }

    const getIconicCharacterQuote = (text) => {
      if (!text) return { subtitle: "", description: "" };

      // Splitting and extracting parts
      const [jpPart, enPart] = text.split("｜\n“"); // Japanese and English parts
      const jpQuote = jpPart
        .split("\n")[0]
        ?.trim()
        .replace(/['"｜“”]/g, ""); // Clean Japanese subtitle
      const jpBy = jpPart.split("\n")[1]?.split("｜")[0]?.trim(); // Japanese description

      const enQuote = enPart
        ?.split("\n")[0]
        ?.trim()
        .replace(/['"｜“”]/g, ""); // Clean English subtitle
      const enBy = enPart?.split("\n")[1]?.split("｜")[0]?.trim(); // English description

      const subtitle = language === "ja" ? jpQuote : enQuote;
      const description = language === "ja" ? jpBy : enBy;

      return { subtitle, description };
    };

    const getLocalizedText = (text) => {
      if (!text) return "";
      const [jp, en] = text.includes("｜") ? text.split("｜") : [text, text];

      return language === "ja" ? jp : en;
    };

    const config = [
      {
        title: "astrology_page_career_title",
        icon: career,
        subtitle: getLocalizedText(advice.param1),
        description: getLocalizedText(advice.param2),
        image: characters?.url1,
      },
      {
        title: "astrology_page_love_title",
        icon: love,
        subtitle: getLocalizedText(advice.param3),
        description: getLocalizedText(advice.param4),
        image: characters?.url2,
      },
      {
        title: "astrology_page_money_title",
        icon: money,
        subtitle: getLocalizedText(advice.param5),
        description: getLocalizedText(advice.param6),
        image: characters?.url3,
      },
      {
        title: "astrology_page_health_title",
        icon: health,
        subtitle: getLocalizedText(advice.param7),
        description: getLocalizedText(advice.param8),
        image: characters?.url4,
      },
      {
        title: "astrology_page_action_title",
        icon: action,
        subtitle: "",
        description: getLocalizedText(astro.param2),
        image: characters?.url5,
      },
      {
        title: "astrology_page_advice_title",
        icon: adviceIcon,
        subtitle: "",
        description: getLocalizedText(astro.param4),
        image: characters?.url6,
      },
      {
        title: "astrology_page_today_Dish_title",
        icon: todayDish,
        subtitle: "",
        description: getLocalizedText(astro.param3),
        image: characters?.url7,
      },
      {
        title: "astrology_page_iconic_character_quotes_title",
        icon: iconicQuotes,
        ...getIconicCharacterQuote(astro.param5),
        image: characters?.url8,
      },
      {
        title: "astrology_page_nice_day_title",
        icon: niceDay,
        subtitle: "",
        description: "",
        image: characters,
        isNiceDay: true,
      },
    ];

    setMappedAstrologyConfig(config);
  }, [i18n.language, data, characters]);

  useEffect(() => {
    if (isTransparent) {
      document.body.style.overflow = "scroll";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isTransparent]);

  useEffect(() => {
    const lastViewed = localStorage.getItem("ASTROLOGY_LAST_VIEWED_DATE");
    if (lastViewed !== new Date().toISOString()?.split("T")?.[0]) {
      setIsTransparent(true);
    } else {
      setIsTransparent(false);
    }
  }, []);

  const handleButtonClick = () => {
    const currentTimestamp = new Date().toISOString()?.split("T")?.[0];
    localStorage.setItem("ASTROLOGY_LAST_VIEWED_DATE", currentTimestamp);
    setIsTransparent(false);
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${currentDate.getFullYear()}`;

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <>
      <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between ">
        <p className="font-semibold text-[20px] text-[#414853]">
          <RenderTranslatedText i8nKey={"astrology_page_title"} />
        </p>
      </div>

      <div className="max-w-[450px] mx-auto px-5 otaPageBg pt-4 relative pb-16">
        <p className="text-[14px] mb-4 mt-4">
          <RenderTranslatedText i8nKey={"astrology_page_tranparent_top_text"} />
        </p>
        <div className="flex justify-between items-center ">
          <p className="text-[14px] font-semibold">
            <RenderTranslatedText i8nKey={"astrology_page_today_astrology"} />
          </p>
          <div className="flex items-center gap-2">
            <img className="w-6 h-6" src={calender} alt="calender" />
            <p className="text-[14px] font-bold">{formattedDate}</p>
          </div>
        </div>
        <div className="relative h-full pb-20">
          {isTransparent && (
            <div className="absolute bg-white bg-opacity-20 flex flex-col justify-center items-center z-20 p-5">
              <img className="w-48 h-48" src={mainIcon} alt="calender" />
              <p className="text-xl font-bold my-4">
                <RenderTranslatedText
                  i8nKey={"astrology_page_ready_for_your_daily_spark_title"}
                />
              </p>
              <p className="text-sm text-center mb-10">
                <RenderTranslatedText
                  i8nKey={"astrology_page_ready_for_your_daily_spark_subtitle"}
                />
              </p>
              <OtaGradientButton
                className="px-5 py-2 rounded-xl fixed left-[50%] translate-x-[-50%] bottom-24"
                onClick={handleButtonClick}
              >
                <p className="font-semibold">
                  <RenderTranslatedText
                    i8nKey={"astrology_page_get_my_daily_insight_cta"}
                  />
                </p>
              </OtaGradientButton>
            </div>
          )}

          <div
            className={`transition-opacity pb-8 ${
              isTransparent ? "opacity-10" : "opacity-100"
            }`}
          >
            {mappedAstrologyConfig
              .slice(0, isTransparent ? 1 : mappedAstrologyConfig.length)
              .map((item, index) => (
                <div className="my-4">
                  <AstroPredictionCard
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    icon={item.icon}
                    image={item?.image}
                    isNiceDay={item?.isNiceDay}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
