import React from "react";

export default function OtaGradientOutlineButton({
  children,
  onClick = () => {},
  className,
}) {
  return (
    <button
      className={`otaGradientOutlineButton ${className}`}
      onClick={onClick}
    >
      <span className="gradient-text">{children}</span>
    </button>
  );
}
