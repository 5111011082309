import { useNavigate } from "react-router-dom";
import RenderTranslatedText from "../../../../components/RenderTranslatedText";
import { crossIcon } from "../../../../assets/icons";

export default function NewTagMatchedModal({
  color,
  characterSrc,
  hashtags,
  description,
  tag,
  title,
  handleClose = () => {},
}) {
  const navigate = useNavigate();
  const handlePicBook = () => navigate("/picture-book");
  return (
    <div
      className="fixed w-full h-full top-0 bottom-0 z-50"
      style={{
        backdropFilter: "blur(3px) brightness(40%)",
      }}
    >
      <div
        className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50 bg-white w-[320px] min-h-[250px] rounded-lg p-4"
        style={{
          border: "4px solid #FCC500",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="mt-[-64px] mb-10">
          <p className="text-[24px] font-bold text-white text-center">
            <RenderTranslatedText
              i8nKey={"compatibility_page_new_tag_modal_congratulations"}
            />
            <button
              className="absolute top-[-42px] right-0 w-6 h-6"
              onClick={handleClose}
              style={{ zIndex: 9 }}
            >
              <img src={crossIcon} className=" bg-white rounded-full p-1.5 " />
            </button>
          </p>
        </div>
        <div className="w-3 h-3 rounded-full bg-[#FCC500] fixed top-2 left-2" />
        <div className="w-3 h-3 rounded-full bg-[#FCC500] fixed top-2 right-2" />
        <div className="w-3 h-3 rounded-full bg-[#FCC500] fixed bottom-2 left-2" />
        <div className="w-3 h-3 rounded-full bg-[#FCC500] fixed bottom-2 right-2" />
        <div className="flex items-center justify-between mt-3">
          <p className="text-[16px] text-[#FCC500] font-bold">NEW</p>
          <p className="text-[15px] font-bold text-[#5E5E5E]">
            <RenderTranslatedText
              i8nKey={"compatibility_page_new_tag_modal_title_text"}
            />
          </p>
        </div>
        <div
          className="grid justify-between gap-2 mt-3"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="w-full">
            <p className="font-bold text-[20px]">
              No.1{" "}
              <span className="ml-2" style={{ color }}>
                {tag}
              </span>
            </p>
            <img src={characterSrc} className="w-full" />
          </div>
          <div className="w-full">
            <p className="font-semibold text-[12px]" style={{ color }}>
              {title}
            </p>
            <p className=" font-semibold text-[8px] mt-2" style={{ color }}>
              {hashtags}
            </p>
            <p className=" font-semibold text-[10px] mt-2">{description}</p>
          </div>
        </div>
        <div className="flex items-center justify-center mt-3">
          <button
            className="text-[12px] font-bold text-white bg-[#FCC500] rounded-xl p-2 shadow-md"
            onClick={handlePicBook}
          >
            <RenderTranslatedText
              i8nKey={"compatibility_page_new_tag_modal_checkout_all_chars"}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
