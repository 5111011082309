import React from "react";
import useResetScroll from "../../utils/useResetScroll";
import Card from "./components/Card";
import { profileCards } from "./constants";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { bell } from "../../assets/profileIcons";
import { logoutApp } from "../../utils/auth";
import TopCard from "./components/TopCard";

export default function Profile() {
  useResetScroll();
  return (
    <>
      <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
        <p className="font-semibold text-[20px] text-[#414853]">
          <RenderTranslatedText i8nKey={"profile_page_title"} />
        </p>
        {/* <img src={bell} alt="notifications" /> */}
      </div>
      <div className="max-w-[450px] mx-auto px-5 otaPageBg pt-4">
        <div className="bg-">
          <TopCard />
          {profileCards.map((data, index) => (
            <Card
              key={index}
              title={data.titleKey}
              description={data.descriptionKey}
              icon={data.icon}
              href={data.link}
            />
          ))}
          <button
            onClick={logoutApp}
            className="text-red-500 text-sm text-center rounded-xl border-2 border-red-500 w-full p-2 mb-10 bg-white"
          >
            <RenderTranslatedText i8nKey={"profile_page_logout"} />
          </button>
        </div>
      </div>
    </>
  );
}
