import React from "react";
import RenderTranslatedText from "../../../components/RenderTranslatedText";

const AstroPredictionCard = ({
  title,
  subtitle,
  description,
  icon,
  image,
  isNiceDay = false,
}) => {
  const mainColor = localStorage.getItem("USER_QR_COLOR");
  const userType = localStorage.getItem("USER_NATIVE_TYPE");

  const showSubHeadingBold = [
    "astrology_page_action_title",
    "astrology_page_advice_title",
    "astrology_page_today_Dish_title",
  ].includes(title);

  return (
    <div
      className="w-[100%] mx-auto flex flex-col gap-4 bg-white rounded-[20px] rounded-bl-[20px] h-[350px] overflow-hidden relative"
      style={{
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        background:
          "linear-gradient(149.3deg, #ECFCFF 1.69%, #EFF5FF 32.67%, #F9F5FF 70.67%)",
      }}
    >
      <div className="absolute left-8 bottom-8 grid grid-cols-6 gap-1.5">
        {Array(24)
          .fill("")
          .map(() => {
            return (
              <div
                className="z-0 h-[4px] w-[4px] rounded-full opacity-70"
                style={{ background: mainColor }}
              />
            );
          })}
      </div>

      <div
        className="w-[75%]  absolute top-[220px] left-[110%]"
        style={{ transform: "translate(-45%)" }}
      >
        <div
          className="w-14 h-14 rounded-full opacity-30"
          style={{ background: mainColor }}
        />
      </div>

      <div
        className="w-[60%] flex items-center justify-between absolute bottom-8 left-[45%]"
        style={{ transform: "translate(-25%) rotate(25deg)" }}
      >
        <div
          className="w-5 h-5 rounded-full opacity-80"
          style={{ background: mainColor }}
        />
        <div
          className="w-4 h-4 rounded-full opacity-80"
          style={{ background: mainColor }}
        />
      </div>

      <div
        className="w-3 h-3 rounded-full absolute left-24 bottom-8"
        style={{ background: mainColor }}
      />

      <div
        className="w-[75%] flex items-center justify-between absolute top-[330px] left-[70%]"
        style={{ transform: "translate(-50%)" }}
      >
        <div
          className="w-8 h-8 rounded-full opacity-50"
          style={{ background: mainColor }}
        />
      </div>

      <div className="w-full flex flex-col items-center gap-5 z-10">
        <div className="flex flex-col items-center text-center mx-auto w-full">
          <div className="flex items-center space-x-2 mt-8 mb-4">
            <img className="w-6 h-6" src={icon} alt={title} />
            <p className="text-2xl ml-2 gradient-text text-center font-bold">
              <RenderTranslatedText i8nKey={title} />
            </p>
          </div>

          <p className="font-semibold text-xl mb-2 mx-4">{subtitle}</p>
          <p
            className={`text-lg mx-4 ${
              showSubHeadingBold ? "font-semibold text-xl" : ""
            }`}
          >
            {description}
          </p>
        </div>
        {isNiceDay ? (
          <div className="grid grid-cols-4 items-center justify-center gap-4 p-5 mt-[-36px]">
            {Object.values(image ?? {}).map((url) => {
              return <img src={url} className="w-[80px] aspect-square" />;
            })}
          </div>
        ) : (
          <img
            alt=""
            src={image}
            style={{
              zIndex: 1,
              width: "150px",
              height: "150px",
              position: "absolute",
              bottom: "24px",
              left: "53%",
              transform: "translateX(-50%)",
            }}
          />
        )}

        <div
          style={{ background: mainColor, opacity: 0.3 }}
          className="w-[660px] h-[708px] rounded-[300px] absolute bottom-[-600px] z-0"
        />
      </div>
    </div>
  );
};

export default AstroPredictionCard;
