import React, { useEffect, useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import CharactersRow from "./components/CharactersRow";
import { useTranslation } from "react-i18next";
import OtaGradientButton from "../../components/OtaGradientButton";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import { characterCategoriesConfig } from "./constants";

export default function Characters() {
  useResetScroll();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    characterCategoriesConfig?.[0]?.value
  );

  const userId = localStorage.getItem("USER_ID");

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/character?user_id=${userId}&category=${encodeURIComponent(
        selectedCategory
      )}`
    );
    const apiData = await apiResponse.json();
    setData(apiData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);

  const handleCreateCharacter = () => navigate("/characters/create");

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="otaPageBg w-full min-h-[100svh] relative">
      <div className="max-w-[450px] mx-auto relative">
        <div className="w-full min-h-[100svh]">
          <div className="p-5 w-full h-[60px] bg-white flex items-center">
            <div className="max-w-[450px] mx-auto w-full">
              <p className="font-semibold text-[20px] text-[#414853]">
                <RenderTranslatedText i8nKey={"characters_page_title"} />
              </p>
            </div>
          </div>
          <div className="p-5 max-w-[450px] mx-auto">
            <div className="grid grid-cols-4 gap-10 px-5 mb-10">
              {characterCategoriesConfig.map(
                ({ activeIcon, icon, labelKey, value, color }) => {
                  const isActive = value === selectedCategory;
                  return (
                    <div
                      className="flex flex-col items-center gap-1"
                      key={value}
                      onClick={() => setSelectedCategory(value)}
                    >
                      <img
                        className="w-full"
                        src={isActive ? activeIcon : icon}
                      />
                      <p
                        className="text-[14px] font-semibold"
                        style={{ color: color }}
                      >
                        <RenderTranslatedText i8nKey={labelKey} />
                      </p>
                    </div>
                  );
                }
              )}
            </div>
            <CharactersRow
              title={t("characters_page_my_characters")}
              characters={data?.my_characters}
              catKey={"my_characters"}
              selectedCategory={selectedCategory}
            />
            <CharactersRow
              title={t("characters_page_recommended_characters")}
              characters={data?.recommended}
              catKey={"recommended"}
              selectedCategory={selectedCategory}
            />
            <CharactersRow
              title={t("characters_page_popular_characters")}
              characters={data?.popular}
              selectedCategory={selectedCategory}
              catKey={"popular"}
            />
            <CharactersRow
              title={t("characters_page_new_characters")}
              characters={data?.new}
              selectedCategory={selectedCategory}
              catKey={"new"}
            />
          </div>
        </div>
        {/* <div className="fixed w-max bottom-24 left-[50%] translate-x-[-50%]">
          <OtaGradientButton
            className="px-5 py-2 rounded-xl"
            onClick={handleCreateCharacter}
          >
            <p className="font-semibold">
              <RenderTranslatedText
                i8nKey={"characters_page_create_new_character_cta"}
              />
            </p>
          </OtaGradientButton>
        </div> */}
      </div>
    </div>
  );
}
