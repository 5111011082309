import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchApi from "../../lib/strapi";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import {
  backButton,
  commentIcon,
  eyes,
  heartOutline,
} from "../../assets/icons";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";
import ShareSection from "../../components/ShareSection";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const HomeCategoryArticle = () => {
  useResetScroll();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { documentId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const fetchedArticle = await fetchApi({
          endpoint: "articles",
          wrappedByKey: "data",
          wrappedByList: true,
          query: {
            "populate[0]": "Cover",
            "pagination[pageSize]": "1",
            "sort[0]": "published_date:desc",
            "filters[documentId][$eqi]": documentId,
            locale: language === "ja" ? "ja-JP" : "en",
          },
        });
        setArticle(fetchedArticle[0]);
        console.log(fetchedArticle[0]);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [documentId, language]);

  const hashtags = article?.hashtags_comma_seperated
    ? article?.hashtags_comma_seperated
        ?.split(",")
        .map((tag) => `#${tag.trim()?.replace("#", "")}`)
    : [];

  return loading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <>
      <div className="w-full p-5 h-[60px] flex items-center bg-white">
        <div onClick={() => navigate(-1)} className="flex-shrink-0">
          <img className="w-[24px]" src={backButton} />
        </div>
        <p className="font-semibold text-lg capitalize text-center flex-grow mr-8">
          {article ? article.Category : "Loading..."}
        </p>
      </div>

      <div className="p-5 otaPageBg min-h-[80vh]">
        <div>
          <div key={article.id}>
            <img
              className="w-full h-40 rounded-xl"
              src={article.Cover.url}
              alt={article.id}
            />
            <p className="font-semibold text-lg mt-2">{article.Title}</p>
            <p className="font-semibold my-2">{hashtags.join(" ")}</p>

            {/* <div className="flex space-x-2">
              <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                <img src={eyes} className="w-[18px] object-contain" />
                <p className="text-[12px] text-[#424242]">0</p>
              </div>
              <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                <img src={heartOutline} className="w-[18px] object-contain" />
                <p className="text-[12px] text-[#424242]">0</p>
              </div>
              <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                <img src={commentIcon} className="w-[18px] object-contain" />
                <p className="text-[12px] text-[#424242]">0</p>
              </div>
            </div> */}

            <Markdown remarkPlugins={[remarkGfm]} className={"article-content"}>
              {article.content}
            </Markdown>
          </div>
          <div className="my-10">
            <ShareSection
              shareLink={window.location.href}
              customShareTitle={"Check out this article at"}
              cardTitle={t(
                "home_category_blog_detail_page_share_section_title"
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCategoryArticle;
