import { useState } from "react";
import { uploadCloud } from "../../assets/icons";
import RenderTranslatedText from "../RenderTranslatedText";
import { getFileUrl } from "./constants";

export default function ImageUpload({ label, onUpload = () => {} }) {
  const [uploadedImage, setUploadedImage] = useState(null);

  const onInput = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const res = await fetch(`https://api.be-native.life/api/file/upload`, {
        method: "POST",
        body: formData,
      });
      const data = await res.json();
      const filePath = getFileUrl(data?.file_name);
      setUploadedImage(filePath);
      onUpload(data?.file_name);
    } catch (_) {}
  };

  return (
    <div>
      <p className="text-[14px] otaLight font-normal">{label}</p>
      {uploadedImage ? (
        <div className="w-full flex flex-col items-center gap-3 mt-2">
          <img
            src={uploadedImage}
            className="w-[120px] object-cover object-center aspect-square rounded-2xl"
          />
          <div className="flex flex-row items-center justify-center gap-3">
            <p
              className="text-[#EF423B] font-semibold text-[12px] text-center"
              onClick={() => setUploadedImage(null)}
            >
              <RenderTranslatedText
                i8nKey={"characters_create_page_image_input_delete_text"}
              />
            </p>
            <label htmlFor="fileInput">
              <input
                type="file"
                id="fileInput"
                accept="image/jpeg,image/png"
                className="hidden"
                onChange={onInput}
              />
              <p className="otaFont font-semibold text-[12px] text-center">
                <RenderTranslatedText
                  i8nKey={"characters_create_page_image_input_change_text"}
                />
              </p>
            </label>
          </div>
        </div>
      ) : (
        <label htmlFor="fileInput">
          <div
            style={{ border: "1px dashed #3969E7" }}
            className="bg-white rounded-xl mt-2 p-5 flex items-center gap-5"
          >
            <input
              type="file"
              id="fileInput"
              accept="image/jpeg,image/png"
              className="hidden"
              onChange={onInput}
            />
            <img src={uploadCloud} />
            <div>
              <p className="otaFont font-bold text-[12px]">
                <RenderTranslatedText
                  i8nKey={"characters_create_page_image_input_subTitle"}
                />
              </p>
              <p className="otaLight text-[10px]">
                <RenderTranslatedText
                  i8nKey={"characters_create_page_image_input_info"}
                />
              </p>
            </div>
          </div>
        </label>
      )}
    </div>
  );
}
