import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { backButton } from "../../assets/icons";
import useResetScroll from "../../utils/useResetScroll";
import { getFileUrl } from "../../components/ImageUpload/constants";
import { useTranslation } from "react-i18next";
import OtaGradientButton from "../../components/OtaGradientButton";

export default function UserPageWithCommunity() {
  useResetScroll();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isComLoading, setIsComLoading] = useState(true);
  const [comData, setComData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsComLoading(true);
      try {
        const response = await fetch(
          `https://api.be-native.life/api/travel/related_favcards/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch community data");
        }
        const data = await response.json();
        setComData(data);
      } catch (_) {
      } finally {
        setIsComLoading(false);
      }
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const userInfoAPIRes = await fetch(
          ` https://api.be-native.life/api/users/${userId}`
        );
        const userInfo = await userInfoAPIRes.json();

        const date = userInfo?.dob?.split("-")?.[2];
        const month = userInfo?.dob?.split("-")?.[1];
        const year = userInfo?.dob?.split("-")?.[0];

        const apiResponse = await fetch(
          ` https://api.be-native.life/api/friends/friends_data/${year}/${month}/${date}?lang=${language}`
        );
        const apiData = await apiResponse.json();
        setData({ ...(apiData ?? {}), name: userInfo?.input_name });
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId, language]);

  // Top Card
  const mainColor = data?.hex_color;
  const userName = data?.name;
  const userType = data?.param7;
  const personalChara = data?.param8;
  const catchTitle = data?.param9;

  const selfUserId = localStorage.getItem("USER_ID");

  return isComLoading || isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="otaPageBg w-full min-h-[100svh] relative">
      <div className="max-w-[450px] mx-auto relative">
        <div className="w-full min-h-[100svh]">
          <div className="p-5 w-full h-[60px] bg-white flex items-center">
            <div className="max-w-[450px] mx-auto w-full">
              <div className="flex items-center gap-2">
                <div onClick={() => navigate(-1)}>
                  <img className="w-[24px]" src={backButton} />
                </div>
                <p className="font-semibold text-[17px] text-[#414853]">
                  {userName}
                </p>
              </div>
            </div>
          </div>
          <div className="p-5">
            <div
              className="w-[100%] mx-auto flex flex-col gap-4 bg-white rounded-br-[20px] rounded-bl-[20px] min-h-[460px] overflow-hidden relative"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
            >
              {/* Design Elements Start */}
              <div className="absolute right-5 top-10 grid grid-cols-4 gap-2">
                {Array(24)
                  .fill("")
                  .map(() => {
                    return (
                      <div
                        className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                        style={{ background: mainColor }}
                      />
                    );
                  })}
              </div>

              <div className="absolute left-5 bottom-28 grid grid-cols-6 gap-2">
                {Array(24)
                  .fill("")
                  .map(() => {
                    return (
                      <div
                        className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                        style={{ background: mainColor }}
                      />
                    );
                  })}
              </div>

              <div
                className="w-[75%] flex items-center justify-between absolute top-[110px] left-[50%]"
                style={{ transform: "translate(-45%)" }}
              >
                <div
                  className="w-7 h-7 rounded-full opacity-80"
                  style={{ background: mainColor }}
                />
                <div
                  className="w-16 h-16 rounded-full opacity-30"
                  style={{ background: mainColor }}
                />
              </div>

              <div
                className="w-5 h-5 rounded-full absolute top-[180px] left-8"
                style={{ background: mainColor }}
              />

              <div
                className="w-[75%] flex items-center justify-between absolute top-[230px] left-[50%]"
                style={{ transform: "translate(-50%)" }}
              >
                <div
                  className="w-12 h-12 rounded-full opacity-50"
                  style={{ background: mainColor }}
                />
                <div
                  className="w-7 h-7 rounded-full opacity-80"
                  style={{ background: mainColor }}
                />
              </div>
              {/* Design Elements End */}

              <div
                className="w-full flex flex-col items-center gap-5 z-10"
                style={{ borderTop: `8px solid ${mainColor}` }}
              >
                <h1
                  className="text-[18px] text-black font-bold text-center mt-10 w-[70%] mx-auto"
                  dangerouslySetInnerHTML={{
                    __html: t("native_card_title", { userName }),
                  }}
                />
                <div className="flex flex-col gap-1 mt-1 w-[50%] mx-auto">
                  <p
                    style={{ color: mainColor }}
                    className="text-[14px] text-center font-semibold"
                  >
                    {personalChara}
                  </p>
                  <p
                    style={{ color: mainColor }}
                    className="text-[42px] text-center font-bold"
                  >
                    {userType}
                  </p>
                </div>
                {userType && (
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${userType}`}
                    style={{
                      zIndex: 1,
                      width: "200px",
                      height: "200px",
                      marginTop: "-24px",
                    }}
                  />
                )}
                <div className="flex flex-col gap-1 mt-2">
                  <p className="text-[14px] text-black font-semibold text-center w-[90%] m-auto mb-5">
                    {catchTitle}
                  </p>
                </div>
                <div
                  style={{ background: mainColor, opacity: 0.3 }}
                  className="w-[660px] h-[708px] rounded-[860px] absolute bottom-[-546px] z-0"
                />
              </div>
            </div>
            <OtaGradientButton
              onClick={() => {
                navigate(`/compatibility/${selfUserId}/${userId}`);
              }}
              className="border-none max-w-[400px] w-[90%] mx-auto min-h-[48px] px-8 flex items-center justify-center rounded-[12px] gap-3 bottom-24 z-50 py-2 mt-6"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background: mainColor,
                zIndex: 999,
              }}
            >
              <p className="text-white text-[16px] font-semibold">
                <RenderTranslatedText
                  i8nKey={"user_profile_comm_page_compatibility_check_CTA"}
                />
              </p>
            </OtaGradientButton>
            {comData?.length > 0 ? (
              <p className="font-bold text-[18px] mt-10">
                <RenderTranslatedText
                  i8nKey={"user_profile_comm_page_participating_communities"}
                />
              </p>
            ) : null}
            <div className="grid grid-cols-3 gap-3 my-5">
              {comData?.map(({ imageId, favcard_id, title }) => {
                return (
                  <div
                    key={imageId}
                    className="w-full flex flex-col items-center gap-3"
                  >
                    <img
                      src={getFileUrl(imageId)}
                      className="w-full aspect-square rounded-lg object-cover"
                    />
                    <p className="text-[12px] text-[#344054] line-clamp-1">
                      {title}
                    </p>
                    <button
                      className="rounded-lg text-[12px] px-3 py-1"
                      style={{
                        border: "2px solid #3969E7",
                      }}
                      onClick={() => navigate(`/community/${favcard_id}`)}
                    >
                      <p className="otaFont">
                        <RenderTranslatedText
                          i8nKey={"user_profile_comm_page_join_cta"}
                        />
                      </p>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
