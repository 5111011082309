import React from "react";
import useResetScroll from "../../utils/useResetScroll";

export default function Tokushoho() {
  useResetScroll();
  return (
    <div className="max-w-[450px] mx-auto px-5">
      <p className="text-[24px] font-semibold text-center my-10">
        特定商取引法に基づく表記
      </p>
      <p className="text-wrap">
        <br />
        <br />
        事業者名：native.株式会社
        <br />
        代表者名：川本 寛之
        <br />
        所在地：東京都渋谷区恵比寿3丁目9−29-2F Relic SQUARE
        <br />
        電話番号：080-3368-3992
        <br />
        メールアドレス：hiro.ganji@be-native.life
        <br />
        販売価格：各サービスの申込ページに表示
        <br />
        商品代金以外の必要料金：なし（但し、インターネット接続料金その他の電気通信回線の通信に関する費用及び通信機器はユーザーにて負担して頂きます）
        <br />
        支払方法：PayPay、クレジットカード決済(Stripe)
        <br />
        支払時期：各サービスの申込時に入力したPayPay、クレジットカードに課金します
        <br />
        引渡時期：即時
        <br />
        返品・交換：申込後のキャンセルはできません
        <br />
        <br />
        以上
      </p>
    </div>
  );
}