export const getDatePickerConfig = (lang) => {
    return lang === 'en' ? {
        date: {
            format: "D",
            caption: "Day",
            step: 1,
        },
        month: {
            format: "M",
            caption: "Mon",
            step: 1,
        },
        year: {
            format: "YYYY",
            caption: "Year",
            step: 1,
        },
    } : {
        year: {
            format: "YYYY",
            caption: "Year",
            step: 1,
        },
        month: {
            format: "M",
            caption: "Mon",
            step: 1,
        },
        date: {
            format: "D",
            caption: "Day",
            step: 1,
        },
    }
}