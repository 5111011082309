import { useNavigate } from "react-router-dom";
import { backArrowIcon, earthIcon } from "../../assets/icons";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import useResetScroll from "../../utils/useResetScroll";

export default function SocialGraph() {
  useResetScroll();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const userId = localStorage.getItem("USER_ID");

  const handleBack = () => navigate(-1);

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/friends/graph/${userId}?max_level=2`
    );
    const apiData = await apiResponse.json();
    setData({
      nodes: apiData?.users?.map(
        ({ user_id, image_url, input_name, hex_color, tag }) => {
          return {
            id: user_id,
            image: `https://api.be-native.life/api/friends/charimg_graph/${tag}`,
            label: `${decodeURIComponent(tag)} ${input_name}`,
            shape: "image",
            size: userId === user_id ? 60 : 25,
          };
        }
      ),
      edges: apiData?.nodes?.map(({ other_user_id, user_id, score }) => {
        return {
          from: user_id,
          to: other_user_id,
          label: `${score}%`,
          length: userId === user_id ? 250 : 500,
          width: userId === user_id ? 3.5 : 1,
          color: "#fcc502",
          font: "16px Arial #fcc502 ",
        };
      }),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options = {
    width: "100%",
    height: "400px",
    edges: {
      color: "#000000",
    },
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="p-5 bg-white w-full min-h-[100svh]">
      <div className="max-w-[450px] mx-auto">
        <div className="flex items-center justify-between">
          <img
            className="w-10 h-10 cursor-pointer"
            src={backArrowIcon}
            onClick={handleBack}
          />
        </div>
        <div className="flex flex-row items-center gap-5 justify-between">
          <div>
            <h1
              className="text-[28px] font-bold text-black"
              style={{ lineHeight: "120%" }}
            >
              <RenderTranslatedText i8nKey={"social_graph_page_title_text"} />
            </h1>
            <p className="text-[14px] text-black mt-5">
              <RenderTranslatedText
                i8nKey={"social_graph_page_title_subtext"}
              />
            </p>
          </div>
          <img src={earthIcon} className="w-16 relative top-[-48px]" />
        </div>
        {data ? (
          <div className="mt-2 w-full h-[400px]">
            <Graph graph={data} options={options} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
