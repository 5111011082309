import React, { useEffect, useState } from "react";
import { authConfig } from "../../utils/auth";
import { logo } from "../../assets/brand";
import { facebookSmall, googleSmall, lineSmall } from "../../assets/social";
import { Link, useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useTranslation } from "react-i18next";
import { backButton } from "../../assets/icons";

const facebookLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=Facebook&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const googleLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=Google&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const lineLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=LINE%E8%AA%8D%E8%A8%BC&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const localStorageReset = () => {
  const referrerId = localStorage.getItem("REFERRER_ID");
  localStorage.clear();
  if (!!referrerId && referrerId !== "undefined")
    localStorage.setItem("REFERRER_ID", referrerId);
};

const handleGoogleLogin = () => {
  localStorageReset();
  window.location.href = googleLoginUri;
};

const handleFacebookLogin = () => {
  localStorageReset();
  window.location.href = facebookLoginUri;
};

const handleLineLogin = () => {
  localStorageReset();
  window.location.href = lineLoginUri;
};

const Login = () => {
  const {
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return isPageLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className=" bg-white w-full h-full">
      <div className="max-w-[450px] mx-auto p-5 flex flex-col  items-center h-[calc(100svh-60px)] otaPageBg">
        <div
          className="w-full flex items-start mt-5 cursor-pointer"
          onClick={handleBack}
        >
          <img src={backButton} />
        </div>
        <div className="w-full">
          <p className="otaDark text-[24px] font-bold text-center my-10 mt-18">
            <RenderTranslatedText i8nKey={"login_page_title"} />
          </p>
          <button
            className="flex flex-row items-center justify-center rounded-[6px] w-[95%] mx-auto px-3 py-3 relative my-5 bg-white"
            style={{ border: "1px solid #D0D5DD" }}
            onClick={handleGoogleLogin}
          >
            <img src={googleSmall} className="w-6 h-6 mx-2" />
            <p className="text-[14px] text-black font-bold">
              <RenderTranslatedText i8nKey={"login_page_google"} />
            </p>
          </button>
          <p className="font-semibold text-[12px] text-[#EC736E] w-[95%] mx-auto">
            <RenderTranslatedText i8nKey={"login_page_google_disclaimer"} />
          </p>
          <button
            className="flex flex-row items-center justify-center rounded-[6px] w-[95%] mx-auto px-3 py-3 relative my-5 bg-white"
            style={{ border: "1px solid #D0D5DD" }}
            onClick={handleLineLogin}
          >
            <img src={lineSmall} className="w-6 h-6 mx-2" />
            <p className="text-[14px] text-black font-bold">
              <RenderTranslatedText i8nKey={"login_page_line"} />
            </p>
          </button>
        </div>
        {language === "en" ? (
          <p className="text-[14px] otaLight font-normal">
            By proceeding, you agree to the{" "}
            <Link to={"/terms-and-conditions"}>
              <span className="font-bold otaDark underline">
                Terms of Service
              </span>
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy">
              <span className="font-bold otaDark underline">
                Privacy Policy
              </span>
            </Link>
            .
          </p>
        ) : (
          <p className="text-[14px] otaLight font-normal">
            <Link to={"/terms-and-conditions"}>
              <span className="font-bold otaDark underline">利用規約</span>
            </Link>{" "}
            および
            <Link to="/privacy-policy">
              <span className="font-bold otaDark underline">
                プライバシーポリシー
              </span>
            </Link>
            に同意の上、登録又はログインへお進みください。
          </p>
        )}
      </div>
      <footer className="bg-gray-100 py-4 text-center w-full">
        <Link
          to="/tokushoho"
          className="text-sm text-gray-600 hover:text-gray-800"
        >
          <RenderTranslatedText i8nKey={"welcome_page_footer_CTA"} />
        </Link>
      </footer>
    </div>
  );
};

export default Login;
