import {
  darkGreenChar,
  greenChar,
  orangeChar,
  pinkChar,
  yellowChar,
  brownChar,
  darkBlueChar,
  greyChar,
  lightBlueChar,
  purpleChar,
} from "../../assets";
import { CustomInput } from "../../components/CustomInput";
import { useEffect, useState } from "react";
import { logo } from "../../assets/brand";
import DatePicker from "react-mobile-datepicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import RenderTranslatedText from '../../components/RenderTranslatedText'
import { useTranslation } from "react-i18next";
import { getDatePickerConfig } from "../../utils/misc";
import { en1, en2, en3, jp1, jp2, jp3 } from "../../assets/issueCardSections";
import { PurpleCharBook } from "../../assets/illustrations";
import OtaGradientButton from "../../components/OtaGradientButton";

const topCharacters = [
  greenChar,
  pinkChar,
  orangeChar,
  yellowChar,
  darkGreenChar,
];

const bottomCharacters = [
  brownChar,
  greyChar,
  purpleChar,
  darkBlueChar,
  lightBlueChar,
];

export default function IssueCard() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [dateInputVal, setDateInputVal] = useState(new Date("2000-07-15"));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const [searchparams] = useSearchParams();

  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleClick = async () => {
    if (!!name && !!date) {
      try {
        setIsPageLoading(true);
        const apiRes = await fetch(
          ` https://api.be-native.life/api/users/${encodeURIComponent(
            name
          )}/${date}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        const data = await apiRes.json();
        localStorage.setItem("USER_ID", data?.user_id);
        localStorage.setItem("USER_NAME", name);
        navigate("/native-card");
      } catch (_) {
        setIsPageLoading(false);
      } finally {
        setIsPageLoading(false);
      }
    }
  };

  const checkIfUserExists = async () => {
    setIsPageLoading(true);
    const tokenResponse = await fetch(
      ` https://api.be-native.life/api/cognito/login?auth_code=${searchparams.get(
        "code"
      )}`,
      {
        method: "POST",
      }
    );
    const tokenData = await tokenResponse.json();
    const refreshToken = tokenData?.refresh_token;
    const accessToken = tokenData?.access_token;
    setAccessToken(accessToken);
    localStorage.setItem("REFRESH_TOKEN", refreshToken);

    if (!!tokenData?.user_id) {
      localStorage.setItem("USER_ID", tokenData?.user_id);
      setTimeout(() => {
        navigate("/native-card");
      }, 500);
    } else {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    checkIfUserExists();
  }, []);

  return isPageLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="max-w-[450px] m-auto flex flex-col justify-between items-center min-h-[100svh] otaPageBg">
      <div className="flex flex-col items-center gap-10 w-full">
        <h1 className="otaDark text-center text-[24px] font-bold mt-16">
          <RenderTranslatedText i8nKey="issue_card_page_title" />
        </h1>
        <div className="flex flex-row items-center justify-center gap-3">
          {topCharacters.map((image, index) => {
            return (
              <img
                src={image}
                alt=""
                key={index}
                className="w-[20%] aspect-square"
              />
            );
          })}
        </div>
        <div>
          <p className="text-[14px] otaLight text-center m-auto w-[90%]">
            <RenderTranslatedText i8nKey="issue_card_page_intro_text" />
          </p>
        </div>
        <div
          className="w-[90%] mx-auto flex flex-col items-center gap-5 bg-white cardShadow rounded-3xl"
          style={{ border: "1px solid #D0D5DD" }}
        >
          <p className="otaDark text-center text-[16px] font-bold w-[90%] mt-5 mx-auto">
            <RenderTranslatedText i8nKey="issue_card_page_intro_sub_text_2" />
          </p>

          <div className="w-[90%] flex flex-col gap-5">
            <CustomInput
              label={t("issue_card_page_nickname_input_label")}
              onChange={(value) => setName(value)}
              value={name}
              placeholder={t("issue_card_page_nickname_input_placeholder")}
              type="text"
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsDatePickerOpen(true);
              }}
            >
              <CustomInput
                label={t("issue_card_page_dob_input_label")}
                onChange={() => {}}
                value={date ?? ""}
                placeholder={language === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
                type="date"
              />
            </div>
            <DatePicker
              value={dateInputVal}
              isOpen={isDatePickerOpen}
              onSelect={(val) => {
                setDateInputVal(val);
                setDate(new Date(val).toISOString()?.split("T")?.[0]);
                setIsDatePickerOpen(false);
              }}
              onCancel={() => {
                setIsDatePickerOpen(false);
              }}
              confirmText={t("dob_picker_ok")}
              cancelText={t("dob_picker_cancel")}
              min={new Date("01-01-1950")}
              dateConfig={getDatePickerConfig(language)}
              showHeader={false}
            />
          </div>
          <OtaGradientButton
            className="rounded-[8px] w-[90%] h-[48px] px-[16px] py-[12px] text-white mt-2"
            onClick={handleClick}
          >
            <RenderTranslatedText i8nKey={"issue_card_issue_button"} />
          </OtaGradientButton>
          <div className="flex flex-row items-center justify-center gap-3 my-5">
            {bottomCharacters.map((image, index) => {
              return (
                <img
                  src={image}
                  alt=""
                  key={index}
                  className="w-[15%] aspect-square"
                />
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center justify-center gap-7 w-[80%] mx-auto mt-5">
          <img src={PurpleCharBook} className="w-[100px]" />
          <div>
            <p className="text-[14px] font-bold">
              <RenderTranslatedText i8nKey={"issue_card_page_steps_heading"} />
            </p>
            <p className="text-[12px] mt-2">
              1. <RenderTranslatedText i8nKey={"issue_card_page_step_1"} />
            </p>
            <p className="text-[12px]">
              2. <RenderTranslatedText i8nKey={"issue_card_page_step_2"} />
            </p>
            <p className="text-[12px]">
              3. <RenderTranslatedText i8nKey={"issue_card_page_step_3"} />
            </p>
          </div>
        </div>
      </div>
      <div className="w-[90%] h-[1px] bg-[#D0D5DD] mt-10 mx-auto" />
      <div>
        <p className="text-[16px] font-semibold mt-10 text-center">
          1. <RenderTranslatedText i8nKey={"issue_card_page_step_1"} />
        </p>
        <img
          src={language === "en" ? en1 : jp1}
          className="w-[90%] mx-auto mt-5"
        />
      </div>

      <div className="mt-5">
        <p className="text-[16px] font-semibold mt-10 text-center">
          2. <RenderTranslatedText i8nKey={"issue_card_page_step_2"} />
        </p>
        <img
          src={language === "en" ? en2 : jp2}
          className="w-[90%] mx-auto mt-5"
        />
      </div>

      <div className="mt-5">
        <p className="text-[16px] font-semibold mt-10 text-center">
          3. <RenderTranslatedText i8nKey={"issue_card_page_step_3"} />
        </p>
        <img
          src={language === "en" ? en3 : jp3}
          className="w-[90%] mx-auto mt-5"
        />
      </div>
      <p className="text-black text-[12px] font-semibold my-20">
        native. 1.8.3
      </p>
    </div>
  );
}
