import React from "react";
import RenderTranslatedText from "../../../components/RenderTranslatedText";
import { Link } from "react-router-dom";

export default function Card({ title, description, icon, href }) {
  return (
    <Link to={href}>
      <div className="border-2 border-gray-300 shadow-sm rounded-xl w-full p-3 my-4">
        <div className="flex items-center space-x-2">
          <img src={icon} className="" />
          <div>
            <p className="font-medium text-xs">
              <RenderTranslatedText i8nKey={title} />
            </p>
            <p className="text-gray-500 text-xs font-medium mt-1">
              <RenderTranslatedText i8nKey={description} />
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
