import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionText } from "../../../../components/PageCommons";
import { chevronRight, crossIcon } from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import RenderTranslatedText from "../../../../components/RenderTranslatedText";
import ShareSection from "../../../../components/ShareSection";

export default function CharacterInfoModal({
  userType,
  matchedList,
  matchedTypesSet,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [sliceLimit, setSliceLimit] = useState(4);

  const mainColor = matchedList?.[0]?.hex_color;

  const onClose = () => navigate(-1);

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/friends/tag_based_modal/${encodeURIComponent(
        userType
      )}?lang=${language}`
    );
    const apiData = await apiResponse.json();
    setData(apiData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language, userType]);

  const handleShowMore = () => {
    setSliceLimit(matchedList?.length);
  };

  const handleCheckCompatibility = (user_id) => {
    navigate(`/compatibility/${localStorage.getItem("USER_ID")}/${user_id}`);
  };

  const handleSelfMatchingPage = () => {
    navigate("/self-matching");
  };

  const handleCheckFriendsOfType = (type) => {
    if (matchedTypesSet?.includes(type)) {
      navigate(`/picture-book?user_type=${encodeURIComponent(type)}`);
    } else {
      navigate("/picture-book-no-friends");
    }
  };

  return (
    <div
      className="w-full h-full fixed top-0 left-0 flex flex-col items-center justify-center"
      style={{ background: "rgba(86, 86, 86, 0.30)", zIndex: 60 }}
    >
      <div className="mt-10 max-w-[450px] w-full flex justify-end pr-5 pb-5">
        <button onClick={onClose}>
          <img src={crossIcon} className=" bg-white rounded-full p-1.5 " />
        </button>
      </div>
      {isLoading ? (
        <div className="max-w-[450px] min-h-[100svh] flex flex-col items-center justify-center gap-2 bg-white h-[100svh] overflow-scroll w-[90%] rounded-xl">
          <p className="text-[24px] font-bold text-neutral-800">
            <RenderTranslatedText i8nKey={"loading_text"} />
          </p>
        </div>
      ) : (
        <div className="max-w-[450px] h-[100svh] overflow-scroll w-[90%] rounded-xl">
          <div className="w-full bg-white py-5">
            <div className="w-full mx-auto flex flex-col gap-4 bg-white min-h-[320px] overflow-hidden relative">
              {/* Design Elements Start */}
              <div className="absolute right-5 top-10 grid grid-cols-4 gap-2">
                {Array(24)
                  .fill("")
                  .map(() => {
                    return (
                      <div
                        className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                        style={{ background: mainColor }}
                      />
                    );
                  })}
              </div>

              <div className="absolute left-5 bottom-20 grid grid-cols-6 gap-2">
                {Array(24)
                  .fill("")
                  .map(() => {
                    return (
                      <div
                        className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                        style={{ background: mainColor }}
                      />
                    );
                  })}
              </div>

              <div
                className="w-[75%] flex items-center justify-between absolute top-[40px] left-[50%]"
                style={{ transform: "translate(-45%)" }}
              >
                <div
                  className="w-7 h-7 rounded-full opacity-80"
                  style={{ background: mainColor }}
                />
                <div
                  className="w-16 h-16 rounded-full opacity-30"
                  style={{ background: mainColor }}
                />
              </div>

              <div
                className="w-5 h-5 rounded-full absolute top-[90px] left-8"
                style={{ background: mainColor }}
              />

              <div
                className="w-[75%] flex items-center justify-between absolute top-[120px] left-[50%]"
                style={{ transform: "translate(-50%)" }}
              >
                <div
                  className="w-12 h-12 rounded-full opacity-50"
                  style={{ background: mainColor }}
                />
                <div
                  className="w-7 h-7 rounded-full opacity-80"
                  style={{ background: mainColor }}
                />
              </div>
              {/* Design Elements End */}

              <div className="w-full flex flex-col items-center gap-5 z-30">
                <div className="flex flex-col gap-1 mt-1 w-[80%] mx-auto">
                  <p
                    style={{ color: mainColor }}
                    className="text-[16px] text-center font-semibold"
                  >
                    {data?.param8}
                  </p>
                  <p
                    style={{ color: mainColor }}
                    className="text-[42px] text-center font-bold"
                  >
                    {userType}
                  </p>
                </div>
                {userType && (
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${userType}`}
                    style={{
                      zIndex: 1,
                      width: "180px",
                      height: "180px",
                      marginTop: "-24px",
                    }}
                  />
                )}
                <div
                  style={{ background: mainColor, opacity: 0.3 }}
                  className="w-[660px] h-[708px] rounded-[860px] absolute bottom-[-620px] z-0"
                />
              </div>
            </div>
            <div className="w-full p-5" style={{ backgroundColor: mainColor }}>
              <p className="text-center text-white font-semibold text-[12px]">
                {data?.param9}
              </p>
            </div>
            <div className="px-5 mt-2">
              <p
                className="text-[24px] font-bold relative my-2"
                style={{ color: mainColor }}
              >
                memo
              </p>
              <p
                className="text-[12px] font-semibold"
                style={{ color: mainColor }}
                dangerouslySetInnerHTML={{ __html: data?.param11 }}
              />
              <div className="mt-5 flex gap-2 items-center justify-center">
                <div
                  className="w-[60px] h-[1px]"
                  style={{ background: mainColor }}
                />
                <p
                  className="text-[16px] font-semibold"
                  style={{ color: mainColor }}
                >
                  <RenderTranslatedText
                    i8nKey={"picturebook_modal_best_fit_char_heading"}
                  />
                </p>
                <div
                  className="w-[60px] h-[1px]"
                  style={{ background: mainColor }}
                />
              </div>
              <div
                className="w-full grid gap-5 mt-4"
                style={{ gridTemplateColumns: "1fr 0.4px 1fr" }}
              >
                <div className="w-full flex flex-col items-center gap-2">
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${data?.partner_tag_1}`}
                    className="w-[120px] h-[120px]"
                    style={{ zIndex: 1 }}
                  />
                  <p
                    className="text-[28px] font-semibold text-center"
                    style={{ color: data?.hex_1 }}
                  >
                    {data?.partner_tag_1}
                  </p>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p
                      className="text-[12px] text-center font-semibold"
                      style={{ color: data?.hex_1 }}
                    >
                      {data?.partner_hashtag_1}
                    </p>
                  </div>
                  <SectionText align="left">{data?.partner_text_1}</SectionText>
                </div>
                <div className="w-[0.4px] h-[100%] bg-black" />
                <div className="w-full flex flex-col items-center gap-2">
                  <img
                    alt=""
                    src={`https://api.be-native.life/api/friends/charimg/${data?.partner_tag_2}`}
                    className="w-[120px] h-[120px]"
                    style={{ zIndex: 1 }}
                  />
                  <p
                    className="text-[28px] font-semibold text-center"
                    style={{ color: data?.hex_2 }}
                  >
                    {data?.partner_tag_2}
                  </p>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p
                      className="text-[12px] text-center font-semibold"
                      style={{ color: data?.hex_2 }}
                    >
                      {data?.partner_hashtag_2}
                    </p>
                  </div>
                  <SectionText align="left">{data?.partner_text_2}</SectionText>
                </div>
              </div>
              <div
                className="w-full grid gap-5 mt-4"
                style={{ gridTemplateColumns: "1fr 0.4px 1fr" }}
              >
                <div className="w-full flex items-center justify-center mb-5">
                  <button
                    onClick={() =>
                      handleCheckFriendsOfType(data?.partner_tag_1)
                    }
                    className="text-[12px] font-bold text-white rounded-3xl py-3 px-0 w-[100%] mx-auto"
                    style={{
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      background: data?.hex_1,
                    }}
                  >
                    {t("picturebook_modal_see_friends_cta", {
                      type: data?.partner_tag_1,
                    })}
                  </button>
                </div>

                <div />

                <div className="w-full flex items-center justify-center mb-5">
                  <button
                    onClick={() =>
                      handleCheckFriendsOfType(data?.partner_tag_2)
                    }
                    className="text-[12px] font-bold text-white rounded-3xl py-3 px-0 w-[100%] mx-auto"
                    style={{
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      background: data?.hex_2,
                    }}
                  >
                    {t("picturebook_modal_see_friends_cta", {
                      type: data?.partner_tag_2,
                    })}
                  </button>
                </div>
              </div>
              <div
                className="rounded-xl overflow-hidden mt-10"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <div
                  className="h-[60px] flex items-center justify-center font-bold text-[20px] text-white"
                  style={{
                    background: mainColor,
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {t("picturebook_modal_type_of_friend_heading", { userType })}
                </div>
                <div className="w-full flex flex-col">
                  {matchedList
                    ?.slice(0, sliceLimit)
                    ?.map(({ input_name, score, user_id }, i) => {
                      return (
                        <div
                          className="w-full grid items-center gap-5 px-5 py-2 pt-3"
                          style={{
                            gridTemplateColumns: "1fr 0.8fr 1fr 0.2fr",
                            borderTop: i !== 0 ? "1px solid #E1E1E1" : "",
                          }}
                          onClick={() => handleCheckCompatibility(user_id)}
                        >
                          <p className="text-[12px] break-words font-bold">
                            {input_name}
                          </p>
                          <div className="flex flex-col gap-0">
                            <p className="text-[10px]">
                              <RenderTranslatedText
                                i8nKey={"picturebook_modal_fit_rate_text"}
                              />
                            </p>
                            <p className="text-[24px]">{score}%</p>
                          </div>
                          <p className="text-[12px] text-[#929292] font-semibold">
                            <RenderTranslatedText
                              i8nKey={
                                "picturebook_modal_check_compatibility_text"
                              }
                            />
                          </p>
                          <div>
                            <img className="w-3 h-3" src={chevronRight} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {matchedList?.length > 4 ? (
                <div className="w-full flex items-center justify-center">
                  <button
                    className="mt-7 text-[18px] font-bold text-white bg-[#FCC500] rounded-3xl py-3 px-5 w-[85%] mx-auto"
                    style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                    onClick={handleShowMore}
                  >
                    <RenderTranslatedText
                      i8nKey={"picturebook_modal_show_more_cta"}
                    />
                  </button>
                </div>
              ) : null}
              <div className="my-10">
                {" "}
                <ShareSection
                  name={localStorage.getItem("USER_NAME")}
                  shareLink={`${
                    window.location.origin
                  }?referrer-id=${localStorage.getItem(
                    "USER_ID"
                  )}&openExternalBrowser=1`}
                  mainColor={localStorage.getItem("USER_QR_COLOR")}
                  showQR
                  customShareTitle={
                    "次のMBTI！生年月日入力のみ。1秒で自分の可能性を拓く｜1秒友達相性診断サービス「native. for Friends」"
                  }
                />
              </div>
              <div className="w-full flex items-center justify-center mb-5">
                <button
                  onClick={handleSelfMatchingPage}
                  className="text-[13px] font-bold text-white bg-[#EC736E] rounded-3xl py-3 px-5 w-[100%] mx-auto"
                  style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                  <RenderTranslatedText
                    i8nKey={"picturebook_modal_self_matching_cta"}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
