import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchApi from "../../lib/strapi";
import { homeCategoriesConfig } from "./constants";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { commentIcon, eyes, heartOutline } from "../../assets/icons";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";

const Home = () => {
  useResetScroll();
  const {
    i18n: { language },
  } = useTranslation();
  const [articles, setArticles] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("anime/manga");
  const navigate = useNavigate();

  const handleArticleSelect = (categoryId, documentId) => {
    const formattedCategoryId = categoryId.replace(/\//g, "_");
    navigate(`/home/${formattedCategoryId}/${documentId}`);
  };

  useEffect(() => {
    const fetchArticles = async (category) => {
      try {
        setLoading(true);
        const articlesData = await fetchApi({
          endpoint: "articles",
          wrappedByKey: "data",
          wrappedByList: true,
          query: {
            "populate[0]": "Cover",
            "pagination[pageSize]": "100",
            "sort[0]": "published_date:desc",
            "filters[Category][$eqi]": category,
            locale: language === "ja" ? "ja-JP" : "en",
          },
        });

        setArticles(articlesData);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles(selectedCategory);
  }, [selectedCategory, language]);

  return (
    <div className="max-w-[450px] mx-auto">
      <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
        <p className="font-semibold text-2xl gradient-text">Ōta</p>
      </div>
      <div className="p-5 otaPageBg min-h-[80vh]">
        <div className="grid grid-cols-4 gap-10 mt-2 mb-6 px-5">
          {homeCategoriesConfig.map(
            ({ value, labelKey, icon, activeIcon, color }) => {
              const isActive =
                selectedCategory.replace(/\//g, "_") ===
                value.replace(/\//g, "_");
              return (
                <div
                  key={value}
                  className="flex flex-col items-center gap-1 cursor-pointer"
                  onClick={() => setSelectedCategory(value)}
                >
                  <img
                    className="w-full"
                    src={isActive ? activeIcon : icon}
                    alt={value}
                  />

                  <p
                    className="text-[14px] font-semibold"
                    style={{ color: color }}
                  >
                    <RenderTranslatedText i8nKey={labelKey} />
                  </p>
                </div>
              );
            }
          )}
        </div>

        {loading ? (
          <div className="min-h-[500px] flex items-center justify-center">
            <p className="text-lg font-bold">
              <RenderTranslatedText i8nKey="loading_text" />
            </p>
          </div>
        ) : (
          <div>
            {articles.map((article, index) => {
              const {
                documentId,
                Title,
                published_date,
                Cover,
                hashtags_comma_seperated,
              } = article;
              const hashtags = hashtags_comma_seperated
                ? hashtags_comma_seperated
                    .split(",")
                    .map((tag) => `#${tag.trim()?.replace("#", "")}`)
                : [];

              return (
                <div
                  onClick={() =>
                    handleArticleSelect(selectedCategory, documentId)
                  }
                  key={documentId}
                >
                  <div className="flex justify-between items-start mb-2 gap-2">
                    <div>
                      <p className="text-[#818897] text-sm">{published_date}</p>
                      <p className="mb-2 line-clamp-3">{Title}</p>
                      <p className="font-semibold">{hashtags.join(" ")}</p>
                    </div>
                    <img
                      className="min-w-28 max-w-28 h-20 rounded-xl object-cover"
                      src={Cover.url}
                      alt={Title}
                    />
                  </div>
                  {index < articles.length - 1 && <hr className="my-4" />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
