/*
 * Fetches data from the Strapi API
 * @param endpoint - The endpoint to fetch from
 * @param query - The query parameters to add to the url
 * @param wrappedByKey - The key to unwrap the response from
 * @param wrappedByList - If the response is a list, unwrap it
 * @returns
 */
export default async function fetchApi({
  endpoint,
  query,
  wrappedByKey,
  wrappedByList,
}) {
  if (endpoint.startsWith("/")) {
    endpoint = endpoint.slice(1);
  }

  const url = new URL(`https://cms.be-native.life/api/${endpoint}`);

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }
  const res = await fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: `Bearer cf849624e87e444566b5798cc82eb8e51836a6a64905f100288075322f62e208c3f68c1e460ba2ae73c3494e5088a24bc016e94186dc5f8bafdc81a1b4c483f923466800beafb0a67b9b0bdc2e3e155ffe494915af91e9199f5d3a0b3d9d350d142c6a3d62f732e5fd344bb4eea35f19339bbe164a04edfcaf4f45e392dffebe`,
    },
  });
  if (!res.ok) {
    throw new Error(`Error fetching data: ${res.statusText}`);
  }

  let data = await res.json();

  if (wrappedByKey && typeof data === "object" && data !== null) {
    data = data[wrappedByKey];
  }

  // If wrappedByList is true, ensure data is an array before accessing index
  // if (wrappedByList && Array.isArray(data)) {
  //   data = data[0];
  // }
  return data;
}
