import { useEffect, useMemo, useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { backArrowIcon, bookIcon } from "../../assets/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import CharacterTypes from "./components/CharacterTypes";
import { useTranslation } from "react-i18next";
import {
  AMinus,
  APlus,
  CMinus,
  CPlus,
  EMinus,
  EPlus,
  FMinus,
  FPlus,
  PMinus,
  PPlus,
} from "../../assets/typePlaceholders";
import useResetScroll from "../../utils/useResetScroll";
import CharacterInfoModal from "./components/CharacterInfoModal";

function findDifference(str1, str2) {
  let difference = "";
  let [longer, shorter] =
    str1.length > str2.length ? [str1, str2] : [str2, str1];
  for (let i = 0; i < longer.length; i++) {
    if (shorter[i] !== longer[i]) {
      difference += longer[i];
    }
  }
  return difference;
}

function findCommonParts(str1, str2) {
  let common = "";

  for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
    if (str1[i] === str2[i]) {
      common += str1[i];
    }
  }

  return common;
}

export default function PictureBook() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useResetScroll();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();

  const userId = localStorage.getItem("USER_ID");

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/friends/${userId}/zukan?lang=${language}`
    );
    const apiData = await apiResponse.json();
    setData(apiData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  const handleBack = () => navigate(-1);

  const types = useMemo(() => {
    const typesRefArray = [
      {
        type: "T",
        title: t("picturebook_page_tree_type"),
        color: "#93E6B6",
      },

      {
        type: "F",
        title: t("picturebook_page_flower_type"),
        color: "#EAC2CB",
      },

      {
        type: "S",
        title: t("picturebook_page_sun_type"),
        color: "#F3C097",
      },

      {
        type: "M",
        title: t("picturebook_page_moon_type"),
        color: "#F2E661",
      },

      {
        type: "Mt",
        title: t("picturebook_page_mountain_type"),
        color: "#92BB77",
      },

      {
        type: "E",
        title: t("picturebook_page_earth_type"),
        color: "#CEAF8D",
      },

      {
        type: "I",
        title: t("picturebook_page_iron_type"),
        color: "#D1D1D1",
      },

      {
        type: "D",
        title: t("picturebook_page_diamond_type"),
        color: "#D3AFE4",
      },

      {
        type: "O",
        title: t("picturebook_page_ocean_type"),
        color: "#A0C2EE",
      },

      {
        type: "W",
        title: t("picturebook_page_water_type"),
        color: "#B8DCED",
      },
    ];
    return typesRefArray?.map((item, index) => {
      const { type } = item;
      const refArray = [
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "A+",
          image_url: APlus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "A-",
          image_url: AMinus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "C+",
          image_url: CPlus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "C-",
          image_url: CMinus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "E+",
          image_url: EPlus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "E-",
          image_url: EMinus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "F+",
          image_url: FPlus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "F-",
          image_url: FMinus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "P+",
          image_url: PPlus,
        },
        {
          character_description: "〇〇の〇〇〇な〇〇〇〇",
          type: "P-",
          image_url: PMinus,
        },
      ];
      const matchedCharacters = data
        ?.filter(({ tag }) => {
          return (
            findCommonParts(tag, type)?.toUpperCase() === type?.toUpperCase()
          );
        })
        ?.map((character) => {
          return {
            ...character,
            type: findDifference(character?.tag, type),
          };
        });
      const typeList = refArray.map((item) => {
        const typesFound = matchedCharacters?.filter(
          ({ type }) => type?.toUpperCase() === item?.type?.toUpperCase()
        );
        if (typesFound?.length > 0) {
          return typesFound[typesFound.length - 1];
        } else {
          return { ...item, isAbsent: true };
        }
      });
      return { ...item, typeList, startIndex: index * 10 };
    });
  }, [data, language]);

  const totalCount = useMemo(() => {
    return types.reduce((acc, { typeList }) => {
      return acc + typeList?.filter(({ isAbsent }) => !isAbsent)?.length;
    }, 0);
  }, [types]);

  const userType = decodeURIComponent(searchParams.get("user_type"));

  const matchedTypesSet = Array.from(new Set(data?.map(({ tag }) => tag)));

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <>
      {userType && userType !== "null" ? (
        <CharacterInfoModal
          userType={userType}
          matchedList={data?.filter(({ tag }) => tag === userType)}
          matchedTypesSet={matchedTypesSet}
        />
      ) : null}
      <div
        className="p-5 bg-white w-full h-[100svh]"
        style={{
          overflow: userType && userType !== "null" ? "hidden" : "auto",
        }}
      >
        <div className="max-w-[450px] mx-auto">
          <div className="flex items-center justify-between">
            <img
              className="w-10 h-10 cursor-pointer"
              src={backArrowIcon}
              onClick={handleBack}
            />
          </div>
          <div className="flex flex-row items-center gap-5 justify-between">
            <div>
              <h1
                className="text-[28px] font-bold text-black"
                style={{ lineHeight: "120%" }}
              >
                <RenderTranslatedText i8nKey={"picturebook_page_title"} />
              </h1>
              <p className="text-[14px] text-black mt-5">
                <RenderTranslatedText i8nKey={"picturebook_page_sub_text"} />
              </p>
            </div>
            <img src={bookIcon} className="w-16 relative top-[-48px]" />
          </div>
          <p className="text-[16px] font-bold text-black mt-10">
            <RenderTranslatedText i8nKey={"picturebook_page_completion_text"} />
          </p>
          <div className="w-[150px] mx-auto flex flex-col mt-5">
            <p className="text-[#FCC500] text-[64px] font-bold">{totalCount}</p>
            <div className="h-[6px] w-full bg-[#FCC500] -rotate-45" />
            <p className="text-[#FCC500] text-[42px] font-bold relative left-[80px]">
              100
            </p>
          </div>
          {types?.map(({ type, title, color, typeList, startIndex }) => {
            return (
              <CharacterTypes
                title={title}
                color={color}
                type={type}
                typeList={typeList}
                startIndex={startIndex}
                allData={data}
              />
            );
          })}
        </div>
        <div className="h-20" />
      </div>
    </>
  );
}
