import { useState } from "react";
import CharacterInfoModal from "../CharacterInfoModal";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CharacterTypes({
  type,
  title,
  color,
  typeList = [],
  startIndex,
}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="my-10">
        <div
          className="w-full flex items-center justify-center h-[56px] font-bold text-white text-[20px] mb-[-48px] relative rounded-t-2xl"
          style={{
            background: color,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          {title}
        </div>

        <div
          className="rounded-2xl w-full mx-auto overflow-hidden"
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            border: "4.5px solid rgba(242, 218, 157, 0.30)",
          }}
        >
          <div
            style={{
              borderTop: "none",
              background: "linear-gradient(180deg, #CDBD93 0%, #FFFDF8 7.5%)",
            }}
            className="w-full pt-16 p-4"
          >
            <div className="w-full grid grid-cols-4 gap-5">
              {typeList?.map(
                (
                  {
                    character_description,
                    image_url,
                    type: subType,
                    isAbsent = false,
                  },
                  i
                ) => {
                  return (
                    <div
                      className="w-full flex flex-col gap-1 items-center relative"
                      style={{ top: isAbsent ? "0px" : "-12px" }}
                      onClick={
                        isAbsent
                          ? () => {}
                          : () =>
                              navigate(
                                `/picture-book?user_type=${encodeURIComponent(
                                  type + subType
                                )}`
                              )
                      }
                    >
                      <img
                        src={image_url}
                        className="object-contain"
                        style={{
                          minWidth:
                            i === 9 ? "59px" : isAbsent ? "60px" : "72px",
                          maxWidth:
                            i === 9 ? "59px" : isAbsent ? "60px" : "72px",
                          aspectRatio: "1/1",
                        }}
                      />
                      <div className="flex flex-row justify-between items-center w-full">
                        <p
                          className="text-[14px] font-bold"
                          style={{ color: isAbsent ? "#C1C1C1" : "#5E5E5E" }}
                        >
                          No.{i + 1 + startIndex}
                        </p>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.23801 11.9999L0.964543 5.76114C0.236676 5.02172 -0.782338 3.08536 0.964543 1.2553C3.14813 -1.03227 5.40105 0.146154 7.23804 2.08712C9.07503 0.146154 11.3279 -1.03227 13.5115 1.2553C15.2584 3.08536 14.2394 5.02172 13.5115 5.76114L7.23806 11.9999V12L7.23804 12L7.23801 12V11.9999Z"
                              fill={isAbsent ? "#C1C1C1" : color}
                            />
                          </svg>
                        </div>
                      </div>
                      <p
                        className="text-[10px] text-center break-words"
                        style={{ color: isAbsent ? "#C1C1C1" : color }}
                      >
                        {character_description}
                      </p>
                      <p
                        className="text-[16px] text-center font-bold"
                        style={{ color: isAbsent ? "#C1C1C1" : color }}
                      >
                        {type}
                        {subType}
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
