import {
  food,
  anime,
  culture,
  travel,
  animeActive,
  foodActive,
  travelActive,
  cultureActive,
} from "../../assets/homecategories";

export const homeCategoriesConfig = [
  {
    labelKey: "home_page_category_anime",
    value: "anime_manga",
    icon: anime,
    activeIcon: animeActive,
    color: "#119F53",
  },
  {
    labelKey: "home_page_category_food",
    value: "food",
    icon: food,
    activeIcon: foodActive,
    color: "#DE4040",
  },
  {
    labelKey: "home_page_category_travel",
    value: "travel",
    icon: travel,
    activeIcon: travelActive,
    color: "#AC6BB7",
  },
  {
    labelKey: "home_page_category_culture",
    value: "culture",
    icon: culture,
    activeIcon: cultureActive,
    color: "#4E74FD",
  },
];
