import { useEffect, useState } from "react";
import { logo } from "../../assets/brand";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import OtaGradientButton from "../../components/OtaGradientButton";
import {
  darkGreenChar,
  greenChar,
  orangeChar,
  pinkChar,
  yellowChar,
  brownChar,
  darkBlueChar,
  greyChar,
  lightBlueChar,
  purpleChar,
} from "../../assets";

const topCharacters = [
  greenChar,
  pinkChar,
  orangeChar,
  yellowChar,
  darkGreenChar,
];

const bottomCharacters = [
  brownChar,
  greyChar,
  purpleChar,
  darkBlueChar,
  lightBlueChar,
];

export default function Welcome() {
  useResetScroll();
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    let timer;
    if (searchparams.get("code")) {
      navigate(`/issue-card?code=${searchparams.get("code")}`);
    } else {
      timer = setTimeout(() => {
        setIsPageLoading(false);
      }, 500);
    }
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, []);

  return isPageLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="max-w-[450px] m-auto flex flex-col justify-between items-center min-h-[100svh] otaPageBg relative">
      <div className="flex flex-col items-center gap-10 w-full">
        <h1 className="otaFont text-[56px] font-bold mt-24">Ōta</h1>
        <p className="text-[20px] otaDark text-center font-semibold mx-auto px-5">
          <RenderTranslatedText i8nKey={"welcome_page_para1"} />
        </p>

        <div className="flex flex-row items-center justify-center gap-3">
          {topCharacters.map((image, index) => {
            return (
              <img
                src={image}
                alt=""
                key={index}
                className="w-[20%] aspect-square"
              />
            );
          })}
        </div>
        <div className="flex flex-row items-center justify-center gap-3">
          {bottomCharacters.map((image, index) => {
            return (
              <img
                src={image}
                alt=""
                key={index}
                className="w-[20%] aspect-square"
              />
            );
          })}
        </div>

        <p className="text-[14px] otaLight text-center mx-auto px-5">
          <RenderTranslatedText i8nKey={"welcome_page_para2"} />
        </p>

        <OtaGradientButton
          className="rounded-[10px] bg-[#333] w-[90%] h-[48px] px-[16px] py-[12px] text-white absolute bottom-20"
          onClick={handleClick}
        >
          <RenderTranslatedText i8nKey={"welcome_page_login_signup_button"} />
        </OtaGradientButton>
      </div>
      <footer className="bg-gray-100 py-4 text-center w-full mt-5">
        <Link
          to="/tokushoho"
          className="text-sm text-gray-600 hover:text-gray-800"
        >
          <RenderTranslatedText i8nKey={"welcome_page_footer_CTA"} />
        </Link>
      </footer>
    </div>
  );
}
