import { anime, animeActive, game, gameActive, movie, movieActive, music, musicActive } from "../../assets/charactercategories";

export const characterCategoriesConfig = [
    {
        labelKey: 'characters_create_page_character_category_anime',
        value: 'anime/manga',
        icon: anime,
        activeIcon: animeActive,
        color: "#119F53"
    },
    {
        labelKey: 'characters_create_page_character_category_game',
        value: 'game',
        icon: game,
        activeIcon: gameActive,
        color: '#DE4040'
    },
    {
        labelKey: 'characters_create_page_character_category_movie',
        value: 'movie',
        icon: movie,
        activeIcon: movieActive,
        color: '#AC6BB7'
    },
    {
        labelKey: 'characters_create_page_character_category_music',
        value: 'anime/music',
        icon: music,
        activeIcon: musicActive,
        color: '#4E74FD'
    },
]

export const sortFn = (a, b) =>
    new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

export const filterFn = ({ creator_user_id }) =>
    creator_user_id && creator_user_id !== "string";