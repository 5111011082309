import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { getFileUrl } from "../../components/ImageUpload/constants";
import { backButton } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { CustomInput } from "../../components/CustomInput";
import OtaGradientButton from "../../components/OtaGradientButton";

function ThreadCreate() {
  const { key } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const navigate = useNavigate();
  const [communityData, setCommunityData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    console.log(key);
    if (key) {
      fetchCommunityData();
    }
  }, [key]);

  const handleCreateThread = async () => {
    if (isPosting) return;
    if (title?.length === 0) return;
    try {
      setIsPosting(true);
      let res = await fetch("https://api.be-native.life/api/travel/favthread", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          parent_id: key,
          creator_user_id: localStorage.getItem("USER_ID"),
          title,
          tag: key,
          param1: description,
          param2: localStorage.getItem("USER_NAME"),
          param3: localStorage.getItem("USER_QR_COLOR"),
          param4: localStorage.getItem("USER_NATIVE_TYPE"),
          param5: "string",
          param6: "string",
          param7: "string",
        }),
      });
      console.log("res", res);
      navigate(`/community/${key}`);
    } catch (_) {
    } finally {
      setIsPosting(false);
    }
  };

  const fetchCommunityData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.be-native.life/api/travel/favcard/${key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch community data");
      }

      const data = await response.json();
      console.log(data);
      setCommunityData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCharacter = () => {
    navigate(`/characters/create?${communityData.favcard_id}`);
  };
  const handleAnimeSelect = (anime) => {
    navigate(`/community/${anime}`);
  };

  return (
    <div>
      <div className="p-5 w-full h-[60px] bg-white max-w-[450px] mx-auto flex items-center border-b-2 border-gray-300">
        {/* <div className="  w-full flex items-center justify-between"> */}
        <div className="flex items-center gap-2">
          <div onClick={() => navigate(-1)}>
            <img className="w-[24px]" src={backButton} alt="Back" />
          </div>
          <p className="font-semibold text-[17px] text-[#414853]">
            <RenderTranslatedText
              i8nKey={"community_create_page_create_thread_cta"}
            />
          </p>
        </div>
      </div>
      <div className=" otaPageBg max-w-[450px] min-h-[80svh] mx-auto">
        <div className="p-5 mx-auto ">
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">Error: {error}</p>
          ) : (
            communityData && (
              <div className="flex items-center space-x-4">
                {communityData.imageId && (
                  <img
                    src={getFileUrl(communityData.imageId)}
                    className="aspect-square object-cover object-center rounded-3xl w-36 overflow-hidden"
                    style={{ border: "1px solid #D0D5DD" }}
                    alt={communityData.title || "Community"}
                  />
                )}
                <div>
                  <h2 className="font-bold text-lg">{communityData.title}</h2>
                  <p className="text-[12px] text-[#555E6C]">
                    <RenderTranslatedText
                      i8nKey={"community_selected_fav_card_subtitle"}
                    />
                  </p>
                </div>
              </div>
            )
          )}
        </div>
        <hr className="border-gray-300 my-2" />

        <div className="p-5">
          <CustomInput
            value={title}
            label={t("create_thread_page_input_title")}
            onChange={(value) => setTitle(value)}
            placeholder={t("create_threadpage_page_input_title_placeholder")}
            type="text"
          />

          <p className="text-[14px] otaLight font-normal mb-2 mt-4">
            <RenderTranslatedText
              i8nKey={"create_thread_page_input_description_title"}
            />
          </p>
          <textarea
            className="bg-white rounded-xl w-full resize-none p-3 border-2"
            placeholder={t("create_thread_page_input_description_placeholder")}
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <OtaGradientButton
            onClick={handleCreateThread}
            className="rounded-[10px] bg-[#333] w-[100%] h-[48px] px-[16px] py-[12px] text-white mt-10"
          >
            <RenderTranslatedText i8nKey={"create_thread_post_thread_cta"} />
          </OtaGradientButton>
        </div>
      </div>
    </div>
  );
}

export default ThreadCreate;
