import React, { useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { CustomInput } from "../../components/CustomInput";
import { useTranslation } from "react-i18next";
import DatePicker from "react-mobile-datepicker";
import { getDatePickerConfig } from "../../utils/misc";
import ImageUpload from "../../components/ImageUpload";
import OtaGradientButton from "../../components/OtaGradientButton";
import Select from "react-select";
import { characterCategoriesConfig } from "../characters/constants";
import { backButton } from "../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";

export default function CharactersCreate() {
  useResetScroll();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const communityId = new URLSearchParams(location.search).get("communityId");
  console.log("communityId", communityId);
  const options = characterCategoriesConfig.map(({ labelKey, icon, value }) => {
    return {
      label: t(labelKey),
      value,
      icon,
    };
  });

  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [dateInputVal, setDateInputVal] = useState(new Date("2000-07-15"));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const characterAssociatedWithCommunity = async (characterId, communityId) => {
    if (!communityId) return;
    try {
      const response = await fetch(
        `https://api.be-native.life/api/character/pair?char_id=${characterId}&favcard_id=${communityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response.json();
      console.log("Character created with community:", data);
    } catch (error) {
      console.error("Error creating character:", error);
    }
  };

  const handleRegister = async () => {
    if (isCreating || !name || !date || !image || !category) return;
    setIsCreating(true);
    try {
      const response = await fetch(`https://api.be-native.life/api/character`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nickname: name,
          dob: date,
          category: category?.value,
          picture: image,
          creator_user_id: localStorage.getItem("USER_ID"),
        }),
      });
      const data = await response.json();
      console.log("charData", data);
      if (communityId) {
        await characterAssociatedWithCommunity(data.char_id, communityId);
      }
      navigate(`/characters/${data?.char_id}`);
    } catch (_) {
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="otaPageBg w-full min-h-[100svh] relative">
      <div className="max-w-[450px] mx-auto relative">
        <div className="w-full min-h-[100svh]">
          <div className="p-5 w-full h-[60px] bg-white flex items-center">
            <div className="max-w-[450px] mx-auto w-full">
              <div className="flex items-center gap-2">
                <div onClick={() => navigate(-1)}>
                  <img className="w-[24px]" src={backButton} />
                </div>
                <p className="font-semibold text-[17px] text-[#414853]">
                  <RenderTranslatedText
                    i8nKey={"characters_create_page_title"}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="p-5 max-w-[450px] mx-auto">
            <div className="w-[95%] mx-auto flex flex-col gap-5">
              <CustomInput
                label={t("characters_create_page_nickname_input_title")}
                onChange={(value) => setName(value)}
                value={name}
                placeholder={t("issue_card_page_nickname_input_placeholder")}
                type="text"
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsDatePickerOpen(true);
                }}
              >
                <CustomInput
                  label={t("characters_create_page_dob_input_title")}
                  onChange={() => {}}
                  value={date ?? ""}
                  placeholder={language === "en" ? "DD/MM/YYYY" : "YYYY/MM/DD"}
                  type="date"
                />
              </div>
              <DatePicker
                value={dateInputVal}
                isOpen={isDatePickerOpen}
                onSelect={(val) => {
                  setDateInputVal(val);
                  setDate(new Date(val).toISOString()?.split("T")?.[0]);
                  setIsDatePickerOpen(false);
                }}
                onCancel={() => {
                  setIsDatePickerOpen(false);
                }}
                confirmText={t("dob_picker_ok")}
                cancelText={t("dob_picker_cancel")}
                min={new Date("01-01-1950")}
                dateConfig={getDatePickerConfig(language)}
                showHeader={false}
              />
              <p className="otaLight text-[14px]">
                *<RenderTranslatedText i8nKey={"issue_card_info_text"} />
              </p>
              <div>
                <p className="text-[14px] otaLight font-normal pb-2">
                  <RenderTranslatedText
                    i8nKey={"characters_create_page_category_input_title"}
                  />
                </p>
                <Select
                  placeholder={t(
                    "characters_create_page_category_input_placeholder"
                  )}
                  options={options}
                  isSearchable={false}
                  formatOptionLabel={(country) => (
                    <div className="flex items-center gap-3">
                      <img className="w-[30px]" src={country.icon} />
                      <span>{country.label}</span>
                    </div>
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "1px solid #D0D5DD",
                      padding: "6px 4px",
                      borderRadius: "12px",
                    }),
                  }}
                  onChange={(value) => setCategory(value)}
                  value={category}
                />
              </div>
              <ImageUpload
                label={t("characters_create_page_image_input_title")}
                onUpload={(image) => setImage(image)}
              />
            </div>
            <OtaGradientButton
              onClick={handleRegister}
              className="rounded-[10px] bg-[#333] w-[100%] h-[48px] px-[16px] py-[12px] text-white mt-10"
            >
              <RenderTranslatedText
                i8nKey={"characters_create_page_register_cta"}
              />
            </OtaGradientButton>
          </div>
        </div>
      </div>
    </div>
  );
}
