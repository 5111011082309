import { calenderIcon } from "../../assets/icons";

export const CustomInput = ({
  label,
  onChange,
  type,
  value,
  placeholder,
  disabled = false,
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <p className="text-[14px] otaLight font-normal ">{label}</p>
      <div
        className="flex flex-row items-center gap-2 rounded-[12px] py-[12px] px-[16px] bg-white"
        style={{ border: "1px solid #D0D5DD" }}
      >
        {type === "date" ? (
          <div
            style={{ color: value ? "#000" : "#9ca3af" }}
            className="w-full flex flex-row items-center justify-between"
          >
            <p>{value || placeholder}</p>
            <img src={calenderIcon} className="w-5 h-5" />
          </div>
        ) : (
          <input
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            className="w-full border-none outline-none bg-transparent"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
