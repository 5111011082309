import nativeScore from '../assets/tour/en/nativeScore.png'
import nativeScoreJp from '../assets/tour/jp/nativeScoreJp.png'

import fourSteps from '../assets/tour/en/fourSteps.png'
import fourStepsJp from '../assets/tour/jp/fourStepsJp.png'

import compatibleCharacter from '../assets/tour/en/twoCompatible.png'
import compatibleCharacterJp from '../assets/tour/jp/twoCompatibleJp.png'

import mbtiCompare from '../assets/tour/en/mbtiCompare.png'
import mbtiCompareJp from '../assets/tour/jp/mbtiCompareJp.png'

import selfDiagnosis from '../assets/tour/en/selfDiagnosis.png'
import selfDiagnosisJp from '../assets/tour/jp/selfDiagnosisJp.png'

import socialGraph from '../assets/tour/en/socialGraph.png'
import socialGraphJp from '../assets/tour/jp/socialGraphJp.png'

import picBook from '../assets/tour/en/picBook.png'
import picBookJp from '../assets/tour/jp/picBookJp.png'


export const createNativeCardTourConfig = (language) => {
    return [
        {
            target: ".native-score-card",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? nativeScore : nativeScoreJp} className="w-[90%]" />
                </div>
            ),
        },
        {
            target: ".four-steps",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? fourSteps : fourStepsJp} className="w-[100%]" />
                </div>
            ),
        },
        {
            target: ".compatible-character",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? compatibleCharacter : compatibleCharacterJp} className="w-[70%]" />
                </div>
            ),
        },
        {
            target: ".mbti-results",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? mbtiCompare : mbtiCompareJp} className="w-[100%]" />
                </div>
            ),
        },
        {
            target: ".self-diagnosis",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? selfDiagnosis : selfDiagnosisJp} className="w-[100%]" />
                </div>
            ),

        },
        {
            target: ".picturebook-icon",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? picBook : picBookJp} className="w-[100%]" />
                </div>
            ),

        },
        {
            target: ".socialgraph-icon",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? socialGraph : socialGraphJp} className="w-[100%]" />
                </div>
            ),

        },
    ];
}

export const createMatchedPageTourConfig = (language) => {
    return [
        {
            target: ".picturebook-icon",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? picBook : picBookJp} className="w-[100%]" />
                </div>
            ),

        },
        {
            target: ".socialgraph-icon",
            content: (
                <div className="w-full flex flex-col items-center">
                    <img src={language === 'en' ? socialGraph : socialGraphJp} className="w-[100%]" />
                </div>
            ),

        },
    ]
}