import {
  compatibility,
  diagnosis,
  friend,
  native,
  selfDiagnosis,
  social,
} from "../../assets/profileIcons";

export const profileCards = [
  {
    titleKey: "profile_page_diagnosis_titleKey",
    descriptionKey: "profile_page_diagnosis_descriptionKey",
    icon: diagnosis,
    link: "/characters",
  },
  {
    titleKey: "profile_page_native_titleKey",
    descriptionKey: "profile_page_native_descriptionKey",
    icon: native,
    link: "/picture-book",
  },
  {
    titleKey: "profile_page_social_titleKey",
    descriptionKey: "profile_page_social_descriptionKey",
    icon: social,
    link: "/social-graph",
  },
  {
    titleKey: "profile_page_self_titleKey",
    descriptionKey: "profile_page_self_descriptionKey",
    icon: selfDiagnosis,
    link: "/native-card",
  },
  {
    titleKey: "profile_page_compatibilty_titleKey",
    descriptionKey: "profile_page_compatibility_descriptionKey",
    icon: compatibility,
    link: "/matched",
  },
  {
    titleKey: "profile_page_friend_titleKey",
    descriptionKey: "profile_page_friend_descriptionKey",
    icon: friend,
    link: "/self-matching",
  },
];
