import Joyride from "react-joyride";

export default function JoyRideTour({
  config,
  onTourEnd = () => {},
  onCloseClick = () => {},
}) {
  return (
    <Joyride
      callback={(state) => {
        if (state.action === "reset") {
          onTourEnd();
        }
        if (state.action === "close") {
          onCloseClick();
        }
      }}
      steps={config}
      styles={{
        options: {
          primaryColor: "#FCC500",
        },
        tooltipFooter: {
          justifyContent: "center",
        },
        tooltipFooterSpacer: {
          display: "none",
        },
        buttonNext: {
          padding: "16px 32px",
          fontSize: "18px",
          fontWeight: 700,
          borderRadius: "12px",
        },
        tooltip: {
          border: "5px solid #FCC500",
          borderRadius: "12px",
        },
      }}
      hideBackButton
      locale={{ next: "OK", last: "OK" }}
      disableScrolling
    />
  );
}
