import { useEffect, useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { useNavigate } from "react-router-dom";
import OtaGradientButton from "../../components/OtaGradientButton";
import { characterCategoriesConfig } from "../characters/constants";
import { useTranslation } from "react-i18next";
import CommunitiesRow from "./components/CommunitiesRow";
import useResetScroll from "../../utils/useResetScroll";

export default function Community() {
  useResetScroll();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    characterCategoriesConfig?.[0]?.value
  );

  const handleCreateCommunity = () => navigate("/community-create");

  const userId = localStorage.getItem("USER_ID");

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      `https://api.be-native.life/api/travel/favcards?user_id=${userId}&category=${encodeURIComponent(
        selectedCategory
      )}`
    );
    const apiData = await apiResponse.json();
    setData(apiData);
    console.log(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className=" otaPageBg w-full min-h-[100svh]">
      <div className="p-5 w-full h-[60px] bg-white flex items-center">
        <div className="max-w-[450px] mx-auto w-full">
          <p className="font-semibold text-[20px] text-[#414853]">
            <RenderTranslatedText i8nKey={"community_page_title"} />
          </p>
        </div>
      </div>
      <div className="p-5 max-w-[450px] mx-auto">
        <div className="grid grid-cols-4 gap-10 p-5">
          {characterCategoriesConfig.map(
            ({ activeIcon, icon, labelKey, value, color }) => {
              const isActive = value === selectedCategory;
              return (
                <div
                  className="flex flex-col items-center gap-1"
                  key={value}
                  onClick={() => setSelectedCategory(value)}
                >
                  <img className="w-full" src={isActive ? activeIcon : icon} />
                  <p
                    className="text-[14px] font-semibold"
                    style={{ color: color }}
                  >
                    <RenderTranslatedText i8nKey={labelKey} />
                  </p>
                </div>
              );
            }
          )}
        </div>
        <CommunitiesRow
          title={t("community_page_my_community")}
          community={data?.my_characters}
          catKey={"my_characters"}
          selectedCategory={selectedCategory}
        />
        <CommunitiesRow
          title={t("characters_page_recommended_characters")}
          community={data?.recommended}
          catKey={"recommended"}
          selectedCategory={selectedCategory}
        />
        <CommunitiesRow
          title={t("characters_page_popular_characters")}
          community={data?.popular}
          selectedCategory={selectedCategory}
          catKey={"popular"}
        />
        <CommunitiesRow
          title={t("characters_page_new_characters")}
          community={data?.new}
          selectedCategory={selectedCategory}
          catKey={"new"}
        />
      </div>

      <div className="fixed w-max bottom-24 left-[50%] translate-x-[-50%]">
        <OtaGradientButton
          className="px-5 py-2 rounded-xl"
          onClick={handleCreateCommunity}
        >
          <p className="font-semibold">
            <RenderTranslatedText
              i8nKey={"community_page_create_new_community_cta"}
            />
          </p>
        </OtaGradientButton>
      </div>
    </div>
  );
}
