import { logoutApp } from "../../utils/auth";
import useResetScroll from "../../utils/useResetScroll";

export default function Reset() {
  useResetScroll();
  return (
    <div className="max-w-[450px] mx-auto h-[100svh] flex flex-col items-center justify-center">
      <button
        onClick={logoutApp}
        className=" border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#ff5252] opacity-85 rounded-[20px] gap-3"
      >
        <p className="text-white text-[16px] font-semibold">リセット</p>
      </button>
      <p className="mt-5">{localStorage.getItem("USER_ID")}</p>
    </div>
  );
}
