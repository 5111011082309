import { useEffect, useState } from "react";
import ShareSection from "../../components/ShareSection";
import { logo } from "../../assets/brand";
import { ConstructionWorker } from "../../assets/illustrations";
import { useNavigate } from "react-router-dom";
import useResetScroll from "../../utils/useResetScroll";
import {
  backArrowIcon,
  bronzeCrown,
  chevronRight,
  goldenCrown,
  medalIcon,
  silverCrown,
} from "../../assets/icons";
import { useTranslation } from "react-i18next";
import RenderTranslatedText from "../../components/RenderTranslatedText";

const MatchedCard = ({
  hexColor,
  name,
  type,
  score,
  otherUserId,
  oneLiner,
  rank,
}) => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("USER_ID");
  const handleCompatibilityCheck = () => {
    navigate(`/compatibility/${userId}/${otherUserId}`);
  };

  let rankColor = "";
  let rankIcon = null;
  switch (rank) {
    case 1:
      rankColor = "#FCC500";
      rankIcon = goldenCrown;
      break;
    case 2:
      rankColor = "#ADADAD";
      rankIcon = silverCrown;
      break;
    case 3:
      rankColor = "#DF8A4C";
      rankIcon = bronzeCrown;
      break;
    default:
      rankColor = "#5297FF";
      rankIcon = null;
  }

  return (
    <div
      className="w-full bg-[#FCFCFC] cursor-pointer"
      onClick={handleCompatibilityCheck}
    >
      <div className="h-[0.5px] w-[80%] mx-auto bg-[#E1E1E1]" />
      <div
        className="flex flex-row items-center justify-between p-3 px-5"
        style={{ paddingTop: rank === 1 ? "24px" : "12px" }}
      >
        <div className="">
          {rankIcon === null ? null : (
            <img className="w-6 h-6" alt="" src={rankIcon} />
          )}
          <div
            className="text-[22px] font-bold flex flex-row items-end gap-[2px]"
            style={{ color: rankColor }}
          >
            {rank}
            <span className="text-[12px] font-normal mb-1"> 位</span>
          </div>
        </div>
        <div>
          {type && (
            <img
              alt=""
              src={`https://api.be-native.life/api/friends/charimg/${type}`}
              className="min-w-[50px] h-[50px]"
              style={{ zIndex: 1 }}
            />
          )}
        </div>
        <div className="w-[100px] flex flex-col gap-1">
          <p className="text-[13px] font-semibold text-black truncate">
            {name}
          </p>
          <p
            className="text-[11px] font-semibold truncate"
            style={{ color: hexColor }}
          >
            {oneLiner}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-[13px] font-bold text-black">{score}%</p>
          <p className="text-[11px] font-semibold" style={{ color: hexColor }}>
            {type}
          </p>
        </div>
        <div>
          <img className="w-3 h-3 ml-3" src={chevronRight} />
        </div>
      </div>
    </div>
  );
};

export default function Ranking() {
  const {t,i18n:{language}}=useTranslation()
  const navigate = useNavigate();
  useResetScroll();
  const [matchedData, setMatchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFullList, setShowFullList] = useState(false);
  const [hideFullListButton, setHideFullListButton] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const apiResponse = await fetch(
      ` https://api.be-native.life/api/users/related/${localStorage.getItem(
        "USER_ID"
      )}?lang=${language}`
    );
    const data = await apiResponse.json();
    const dataArray = await Promise.all(
      data?.map(async ({ user_id, input_name, dob }) => {
        const apiResScore = await fetch(
          ` https://api.be-native.life/api/users/pair_score/${localStorage.getItem(
            "USER_ID"
          )}/${user_id}?lang=${language}`
        );
        const scoreData = await apiResScore.json();
        const apiResInfo = await fetch(
          ` https://api.be-native.life/api/friends/friends_data/${`${
            dob?.split("-")?.[0]
          }`}/${`${dob?.split("-")?.[1]}`}/${`${dob?.split("-")?.[2]}`}?lang=${language}`
        );
        const infoData = await apiResInfo.json();
        return { user_id, input_name, dob, score: scoreData?.score, infoData };
      })
    );
    setMatchedData(dataArray);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  const handleShowFullList = () => {
    setShowFullList(true);
    const timer = setTimeout(() => {
      setHideFullListButton(true);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const userId = localStorage.getItem("USER_ID");
  const shareLink = `${window.location.origin}/native-card?referrer-id=${userId}&openExternalBrowser=1 `;
  const userName = localStorage.getItem("USER_NAME");

  const handleSelfCompatibilityPage = () => {
    navigate("/self-matching");
  };

  const handleBack = () => navigate(-1);

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div className="p-5 bg-white w-full min-h-[100svh]">
      <div className="max-w-[450px] mx-auto">
        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-between">
            <img
              className="w-10 h-10 cursor-pointer"
              src={backArrowIcon}
              onClick={handleBack}
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className="mt-5">
              <p className="text-black font-bold text-[22px]">
                <RenderTranslatedText i8nKey={"ranking_page_title"} />
              </p>
              <p className="text-[14px] text-black mt-1">
                <RenderTranslatedText i8nKey={"ranking_page_description"} />
              </p>
            </div>
            <img className="w-22 h-24 mt-[-24px]" src={medalIcon} />
          </div>
          <div
            style={{
              borderRadius: "16px 16px 0px 0px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            className="bg-[#FCC500] w-full py-3 mt-5 relative top-3"
          >
            <p className="text-white font-bold text-[18px] text-center">
              <RenderTranslatedText i8nKey={"ranking_page_top5_text"} />
            </p>
          </div>
          {matchedData?.length > 0 ? (
            <div
              className="w-[95%] mx-auto"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "0px 0px 16px 16px",
                overflow: "hidden",
              }}
            >
              {matchedData
                ?.filter(({ score }) => score !== "??")
                ?.sort((a, b) => b?.score - a?.score)
                ?.slice(0, 5)
                ?.map(
                  (
                    {
                      user_id,
                      infoData: { hex_color, param7, param8 },
                      input_name,
                      score,
                      dob,
                    },
                    i
                  ) => {
                    return (
                      <MatchedCard
                        key={user_id}
                        hexColor={hex_color}
                        name={input_name}
                        type={param7}
                        score={score}
                        dob={dob}
                        otherUserId={user_id}
                        oneLiner={param8}
                        rank={i + 1}
                      />
                    );
                  }
                )}
              <div className="overflow-hidden pb-1 px-1">
                <div
                  style={{
                    marginBottom: showFullList
                      ? "0px"
                      : `-${(matchedData?.length - 5) * 100}px`,
                    transition: "1s ease",
                  }}
                >
                  {matchedData
                    ?.filter(({ score }) => score !== "??")
                    ?.sort((a, b) => b?.score - a?.score)
                    ?.slice(5, 11)
                    ?.map(
                      (
                        {
                          user_id,
                          infoData: { hex_color, param7, param8 },
                          input_name,
                          score,
                          dob,
                        },
                        i
                      ) => {
                        return (
                          <MatchedCard
                            key={user_id}
                            hexColor={hex_color}
                            name={input_name}
                            type={param7}
                            score={score}
                            dob={dob}
                            otherUserId={user_id}
                            oneLiner={param8}
                            rank={i + 6}
                          />
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="mt-10 w-full h-[400px] flex flex-col justify-center items-center bg-[#F8F3FB] rounded-[16px] "
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                lineHeight: "26px",
              }}
            >
              <img
                src={ConstructionWorker}
                className="w-[200px] h-[200px]"
                alt=""
              />
              <p className="text-center text-[14px]">
                <RenderTranslatedText
                  i8nKey={"matched_page_fallback_message"}
                />
              </p>
            </div>
          )}
        </div>
        {!hideFullListButton &&
        matchedData?.filter(({ score }) => score !== "??")?.length > 5 ? (
          <div className="flex flex-col items-center w-full">
            <button
              className=" mt-7 text-[18px] font-bold text-white bg-[#FCC500] rounded-3xl py-3 px-5 w-[85%] mx-auto"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              onClick={handleShowFullList}
            >
              <RenderTranslatedText i8nKey={"ranking_page_see_more_CTA"} />
            </button>
          </div>
        ) : null}
        <div className="h-10" />
        <ShareSection shareLink={shareLink} showQR name={userName} />
        <button
          onClick={handleSelfCompatibilityPage}
          className="mt-5 border-none w-[85%] mx-auto h-[70px] flex items-center justify-center bg-[#EC736E] rounded-[20px] gap-3"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-white text-[14px] font-semibold">
            <RenderTranslatedText i8nKey={"self_matching_CTA"} />
          </p>
        </button>
      </div>
    </div>
  );
}
